import React, { useState, useRef, useEffect } from "react";
import { Button, Overlay, ToggleButtonGroup } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import { getMyPosition, getMySpecialities, getFilteredCoaches,getLanguages } from "../../../utils/api";
import MultiRangeSlider from "../../../Coach/Common/Inputs/MultiRangeSlider";
import { debounce } from "lodash";
import { toast } from "react-toastify";

const FrontFilterComponent = ({ setcoaches, setisLoading }) => {

    // positions
    const [positionOptions, setPositionOptions] = useState([]);
    const [selectedPosition, setSelectedPosition] = useState([]);

    // speciality
    const [specialityOptions, setspecialityOptions] = useState([])
    const [selectedSpeciality, setselectedSpeciality] = useState([])

    const [selectedLanguage, setselectedLanguage] = useState([])
    const [languageOptions, setselectedLanguageOptions] = useState([])

    // const [selectedMode, setselectedMode] = useState([])

    // expereince
    const [showExp, setShowExp] = useState(false);
    const [expvalue, setexpvalue] = useState({
        min: 0,
        max: 100
    })
    const expTarget = useRef(null);

    // cost
    const [showCost, setShowCost] = useState(false);
    const [costvalue, setcostvalue] = useState({
        min: 0,
        max: 100
    })
    const costTarget = useRef(null);


    const getData = async () => {
        try {

            const { data } = await getMyPosition();
            setPositionOptions(data?.data?.map((i) => ({ value: i?.id, label: i?.title })));

            const specialityRes = await getMySpecialities();
            setspecialityOptions(specialityRes?.data?.data?.map((i) => ({ value: i?.id, label: i?.name })));
            const lang = await getLanguages();
            console.log("dddddddddd",lang)
            setselectedLanguageOptions(
              lang?.data?.data?.map((i) => ({
                value: i?.id,
                label: i?.name,
                // language_id: i?.id,
              }))
            );
        } catch (error) {
            console.error('error++', error);
        }
    };

    const debouncedGetData = useRef(
        debounce(async (positions, specialities, experience, costs,language) => {
            setisLoading(true)
            try {
                const formData = new FormData()

                if (positions?.length > 0) {
                    formData.append('positions', `[${positions?.map((i) => `"${i?.label}"`)}]`)
                }

                if (specialities?.length > 0) {
                    formData.append('specialities', `[${specialities?.map((i) => `"${i?.value}"`)}]`)
                }

                if (experience?.min) {
                    formData.append('min_experience', experience?.min)
                }

                if (experience?.max) {
                    formData.append('max_experience', experience?.max)
                }
                if (experience?.max) {
                    formData.append('languages', `[${language?.map((i) => `"${i?.value}"`)}]`)
                }
                console.log(positions, specialities, experience, costs,language,'0000000000000')
                const { data } = await getFilteredCoaches(formData)
                console.log("ddd2222",data)
                if (data?.status) {
                    setcoaches(data?.data)
                }
                else {
                    toast.error(data?.message)
                }
                setisLoading(false)
            } catch (error) {
                setisLoading(false)
                toast.error(error?.response?.data?.message || error?.message)
                console.error("error++", error);
            }
        }, 1000) // 1000 milliseconds debounce delay
    ).current;

    useEffect(() => {
        getData()
    }, [])


    useEffect(() => {
        // Debounced API call and filtering logic
       
        if(selectedPosition?.length > 0 || selectedPosition?.length > 0 || selectedLanguage.length > 0 || Number(expvalue?.min) !== 0 || Number(expvalue?.max) !== 100 )
        debouncedGetData(selectedPosition, selectedSpeciality, expvalue, costvalue,selectedLanguage);
    }, [selectedPosition, selectedSpeciality, expvalue?.min, expvalue?.max, costvalue,selectedLanguage]);

    const customFilterOptions = (options, filter) => {
        return options.filter(option =>
            option.label.toLowerCase().includes(filter.toLowerCase())
        );
    };

    const onChangeExp = (min, max) => {
        setexpvalue({
            min,
            max
        })
    }

    return (
        <>
            <div className="filter_card d-flex justify-content-between mb-3" style={{scrollbarWidth: 'thin'}}>
                <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
                    <div>
                        <MultiSelect
                            variant="rd_btn"
                            id="tbg-radio-1"
                            options={positionOptions}
                            value={selectedPosition}
                            onChange={setSelectedPosition}
                            className="select-option fs-14"
                            filterOptions={customFilterOptions}
                            overrideStrings={{ "selectSomeItems": "Niche..." }}
                        />
                    </div>
                    <div>
                        <MultiSelect
                            variant="rd_btn"
                            id="tbg-radio-2"
                            options={specialityOptions}
                            value={selectedSpeciality}
                            onChange={setselectedSpeciality}
                            className="select-option fs-14"
                            overrideStrings={{ "selectSomeItems": "Specialties..." }}
                        />
                    </div>
                    <div className="btn-group">
                        <div className=" btn-rd_btn fs-14" ref={expTarget} onClick={() => setShowExp(!showExp)}>
                            Experience
                        </div>
                        <Overlay target={expTarget.current} show={showExp} onHide={() => setShowExp(false)} rootClose placement="bottom" >
                            {({
                                placement: _placement,
                                arrowProps: _arrowProps,
                                show: _show,
                                popper: _popper,
                                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                                ...props
                            }) => (
                                <div
                                    {...props}
                                    style={{
                                        marginTop: '10px',
                                        position: 'absolute',
                                        padding: '2px 10px',
                                        borderRadius: '3px',
                                        zIndex: 9999,
                                        backgroundColor: '#f4f4f4',
                                        border: '2px solid #e5e5e5',
                                        padding: '40px',
                                        ...props.style,
                                    }}
                                >
                                    <MultiRangeSlider
                                        min={0}
                                        max={100}
                                        onChange={({ min, max }) => onChangeExp(min, max)}
                                    />
                                </div>
                            )}
                        </Overlay>
                    </div>
                    <div className="btn-group">
                        <MultiSelect
                            variant="rd_btn"
                            id="tbg-radio-2"
                            options={languageOptions}
                            value={selectedLanguage}
                           
                            onChange={setselectedLanguage}
                            className="select-option fs-14"
                            overrideStrings={{ "selectSomeItems": "Language..." }}
                        />
                    </div>
                    {/* <div className="btn-group">
                        <MultiSelect
                            variant="rd_btn"
                            id="tbg-radio-2"
                            options=""
                            // options={specialityOptions}
                            value=""
                            // value={selectedSpeciality}
                            onChange={setselectedMode}
                            className="select-option fs-14"
                            overrideStrings={{ "selectSomeItems": "Mode..." }}
                        />
                    </div> */}
                    <div className="btn-group">
                        <div className=" btn-rd_btn fs-14" ref={costTarget} onClick={() => setShowCost(!showCost)}>
                            Cost
                        </div>
                        <Overlay target={costTarget.current} show={showCost} onHide={() => setShowCost(false)} rootClose placement="bottom" >
                            {({
                                placement: _placement,
                                arrowProps: _arrowProps,
                                show: _show,
                                popper: _popper,
                                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                                ...props
                            }) => (
                                <div
                                    {...props}
                                    style={{
                                        marginTop: '10px',
                                        position: 'absolute',
                                        padding: '2px 10px',
                                        borderRadius: '3px',
                                        zIndex: 9999,
                                        backgroundColor: '#f4f4f4',
                                        border: '2px solid #e5e5e5',
                                        padding: '40px',
                                        ...props.style,
                                    }}
                                >
                                    <MultiRangeSlider
                                        min={0}
                                        max={100}
                                        onChange={({ min, max }) => onChangeExp(min, max)}
                                    />
                                </div>
                            )}
                        </Overlay>
                    </div>

                </ToggleButtonGroup>
                {/* <Button variant="fltr" className="ms-2">
                    <svg width="13" height="16" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.552734 22.5005C0.553084 22.1028 0.711349 21.7215 0.992734 21.4405L8.66473 13.7685C8.89695 13.5364 9.08116 13.2607 9.20684 12.9574C9.33252 12.654 9.3972 12.3289 9.3972 12.0005C9.3972 11.6721 9.33252 11.347 9.20684 11.0436C9.08116 10.7403 8.89695 10.4647 8.66473 10.2325L1.00273 2.56551C0.729497 2.2826 0.578306 1.9037 0.581723 1.5104C0.585141 1.11711 0.742894 0.74089 1.02101 0.462777C1.29912 0.184665 1.67534 0.0269118 2.06863 0.0234942C2.46193 0.0200766 2.84083 0.171268 3.12373 0.444505L10.7857 8.1055C11.816 9.13777 12.3946 10.5366 12.3946 11.995C12.3946 13.4534 11.816 14.8522 10.7857 15.8845L3.11373 23.5565C2.90425 23.7661 2.63738 23.909 2.34678 23.9671C2.05619 24.0252 1.75489 23.9959 1.48092 23.8829C1.20695 23.77 0.972585 23.5784 0.807393 23.3323C0.6422 23.0863 0.553587 22.7969 0.552734 22.5005Z" fill="#964B00" />
                    </svg>
                </Button> */}
            </div>

        </>
    )
}

export default FrontFilterComponent;