import React from "react";

import { Scheduler } from "@aldabil/react-scheduler";
import { Events } from "./events";
import avatar from "../../../assets/images/dummy_user_img.png";
import { Badge, Button } from "react-bootstrap";

function CoachEventCalendarComponent(){
    return(
        <>
            <Scheduler
            events={Events }
            view="day"
            day={null}
            agenda={null}
            month={null}
            eventRenderer={({ event, ...props }) => {
                {
                return (
                    <div  className="p-1" {...props} style={{ background: "#DADADA",}}>
                        <div className="d-flex w-100 align-items-center ">
                            <span className="me-1">
                                <img className="rounded-circle" width="15" src={avatar} />
                            </span>
                            <h4 className="mb-0 fs-10 fw-400 brand_text_color2">{event.user}</h4>
                        </div>
                        <div className="">
                            <small className="fs-10">
                                {event.u_id}
                            </small>
                            <p className="mb-0 fs-8 fw-400 brand_text_color2">{event.subtitle}</p>
                        </div>

                        
                        <div className="d-flex w-100 justify-content-between">
                            <small className="fs-8">
                                {event.start.toLocaleTimeString("en-US", {
                                timeStyle: "short",
                                })}
                            </small>
                            <small className="fs-8">
                                {event.end.toLocaleTimeString("en-US", { timeStyle: "short" })}
                            </small>
                        </div>
                        <div className="text-center">
                        <Badge bg="" className="badge_status">
                            {event.status}
                        </Badge>
                        </div>

                        
                    </div>
                );
                }
            }}
            />
        </>
    )
}

export default CoachEventCalendarComponent;