import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, NavLink, Row } from "react-bootstrap";
import dummy_coach1_sqr from "../../../assets/images/dummy/dummy_coach1_sqr.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import Lottie from "react-lottie";
import { Link,useNavigate } from "react-router-dom";
import noDataLottie from "../../../assets/lottieFiles/noData.json";

const baseURL = `${process.env.REACT_APP_LOCAL_BASE_URL}`;

function MyProgramsComponents() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noDataLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [allSession, setAllSession] = useState([]);
  const [myCoachList, setMyCoachList] = useState([]);
  const [selectedCoach, setSelectedCoach] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [programType, setProgramType] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("token"),
          "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate",
        },
        url: `${baseURL}/coach/program/user-session-list`,
      };
      const resp = await axios(options);
      setAllSession(resp.data.data);

      const listOptions = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("token"),
          "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate",
        },
        url: `${baseURL}/coach/program/users-coach-list`,
      };
      const listResp = await axios(listOptions);
      setMyCoachList(listResp.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCoachFilter = async (e) => {
    try {
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("token"),
          "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate",
        },
        url: `${baseURL}/coach/program/user-session-list?client_id=${e}&status=${selectedStatus}&program_type=${programType}`,
      };
      const resp = await axios(options);
      setAllSession(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusFilter = async (status) => {
    try {
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("token"),
          "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate",
        },
        url: `${baseURL}/coach/program/user-session-list?client_id=${selectedCoach}&status=${status}&program_type=${programType}`,
      };
      const resp = await axios(options);
      setAllSession(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleProgramFilter = async (type) => {
    try {
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          token: localStorage.getItem("token"),
          "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate",
        },
        url: `${baseURL}/coach/program/user-session-list?client_id=${selectedCoach}&status=${selectedStatus}&program_type=${type}`,
      };
      const resp = await axios(options);
      setAllSession(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();

  const sendData = (item) => {
    console.log("ttttttttt",item)
    navigate('/user/schedule-session', { state: { sessionData: item} });
  }

  return (
    <>
      <Row className="mb-3 align-items-center justify-content-between">
        <Col xs="4">
          <div className="text-start hdng_a">
            <h2 className="m-0">My Programs</h2>
          </div>
        </Col>
        <Col xs="5">
          <InputGroup size="sm" className="mb-3">
            <Form.Control placeholder="Search" />
            <Button>
              <i className="bi bi-search"></i>
            </Button>
          </InputGroup>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="10">
          <Row className="gx-2 align-items-center">
            <Col>
              <Form.Select
                onChange={(e) => {
                  setSelectedCoach(e.target.value);
                  handleCoachFilter(e.target.value);
                }}
              >
                <option>Select Coach</option>
                {myCoachList.map((item) => (
                  <option key={item.coach_id} value={item.coach_id}>
                    {item.user_name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <Form.Select
                onChange={(e) => {
                  setSelectedStatus(e.target.value);
                  handleStatusFilter(e.target.value);
                }}
              >
                <option>Status</option>
                <option value="scheduled">Scheduled</option>
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>
                <option value="cancelled">Cancelled</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Select
                onChange={(e) => {
                  setProgramType(e.target.value);
                  handleProgramFilter(e.target.value);
                }}
              >
                <option>Program Type</option>
                <option value="single_day">Single Day</option>
                <option value="multi_day">MultiDay</option>
              </Form.Select>
            </Col>
          </Row>
        </Col>
      </Row>
      <section className="programs_list_sections">
        <div className="programs_list_header_card">
          <Row>
            <Col xs="4">
              <label className="fs-14 fw-500">Coach Name</label>
            </Col>
            <Col xs="6">
              <label className="fs-14 fw-500">Program Opted</label>
            </Col>
            <Col xs="2">
              <label className="fs-14 fw-500">Progress Status</label>
            </Col>
          </Row>
        </div>
        {allSession.length > 0 ? (
          allSession.map((item, index) => (
            <div className="programs_list_body_card" key={index}>
              <Row className="align-items-center justify-content-between">
                <Col xs="4">
                  <div className="d-flex align-items-center">
                    <span className="user_img">
                      <img
                        className="rounded-circle"
                        width={62}
                        height={62}
                        src={
                          item.coach_profile
                            ? process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH + item.coach_profile
                            : dummy_coach1_sqr
                        }
                      />
                    </span>
                    <div className="">
                      <h5 className="fs-14 fw-500">{item.coach_name}</h5>
                      {/* <p className="fs-10 fw-500 m-0">Saturday, 1st Aug 2024</p> */}
                    </div>
                  </div>
                </Col>
                <Col xs="6">
                  <div className="program_opted_bg">
                    <div className="d-flex justify-content-end">
                      <small className="fs-12 fw-600">{item.total_sessions} Days</small>
                    </div>
                    <div className="d-flex justify-content-between align-items-center my-1">
                      <h5 className="text_brand_color2 fs-15 fw-600 m-0">{item.program_name}</h5>
                      {/* <small className="fs-12 fw-600">1st Aug 2024</small> */}
                    </div>
                    <div className="d-flex align-items-center">
                      {/* <small className="fs-12 fw-500">Basic Emotional Intelligence</small> */}
                    </div>
                  </div>
                </Col>
                {/* {item.completed_percent !== 0 &&
                item.completed_percent !== null &&
                item.completed_percent !== undefined ? ( */}
                  {item.session_day_status  == 'scheduled'  ? (
                  <Col xs="2">
                    <div style={{ width: 100, height: 100 }}>
                      <CircularProgressbar
                        styles={buildStyles({
                          textColor: "#14183E",
                          pathColor: item.completed_percent < 30
                              ? "red"
                              : item.completed_percent >= 30 && item.completed_percent < 80
                                  ? "yellow"
                                  : "green",
                          // trailColor: "#F9F9F9",
                          trailColor:item.completed_percent < 30
                          ? "red"
                          : item.completed_percent >= 30 && item.completed_percent < 80
                              ? "yellow"
                              : "green",
                      })}
                        strokeWidth={14}
                        text={`${item.session_completed}/${item.total_sessions}`}
                        value={item.completed_percent}
                        maxValue={10}
                      />
                    </div>
                  </Col>
                ) : (
                  <Col xs="2">
                    <div className="d-grid">
                      {/* <Link 
                        to={{
                          pathname: `/user/schedule-session`,
                          state: { sessionData: 'item'} // Pass session data here
                        }}
                        style={{ textDecoration: 'none' }}
                      > */}
                        <Button className="fs-12 fw-600" variant="success" size="lg" onClick={() => sendData(item)}>
                          Schedule <br /> Session
                        </Button>
                      {/* </Link> */}
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          ))
        ) : (
          <Col>
            <Lottie options={defaultOptions} height={300} width={400} isStopped={false} isPaused={false} />
          </Col>
        )}
      </section>
    </>
  );
}

export default MyProgramsComponents;

