import axios from "axios";

// const baseURL = `${process.env.REACT_APP_BASE_URL}/api/v1`
const baseURL = `${process.env.REACT_APP_LOCAL_BASE_URL}`
// alert(baseURL)
// const socket_url = `${process.env.REACT_APP_LOCAL_BASE_URL}`
const socket_url =  `${process.env.REACT_APP_BASE_URL}`

const api = (url = "", method, data = {}) => {
  const options = {
    method,
    headers: {
      "content-type": "application/json",
      token: localStorage.getItem("token"),
      "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate",
    },
    data,
    url: `${baseURL}/${url}`,
  };
  return axios(options);
};

const formDataAPI = (url = "", method, data = {}) => {
  const options = {
    method,
    headers: {
      "content-type": "multipart/form-data;",
      token: localStorage.getItem("token"),
      "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate"
    },
    data,
    url: `${baseURL}/${url}`,
  };
  return axios(options);
};

export { api, formDataAPI, socket_url };
