import React, { useEffect, useState } from "react";
import blog_card_img1 from "../../../assets/images/dummy/blog_card_img1.png";
import { getCommentPostById } from "../../../utils/api";

function SocialFeedComment( id ) {

    const [allComments, setAllComments] = useState([]);

    useEffect(() => {
        getAllData()
    }, []);

    const getAllData = async () => {
        const data = await getCommentPostById(id.id);
        setAllComments(data?.data?.data)
    }

    const defaultImage = (e) => {
        e.target.src = 'https://admin.qoach.io/assets/images/blog.png';
    }
    const defaultUserImage = (e) => {
        e.target.src = blog_card_img1;
    }
    return (
        <>
        {
            allComments && allComments.length > 0 ?
            allComments.map((post) => (
            <div className="position-relative d-flex align-items-start user-info mt-3">
                {/* <img style={{ width: '30px', height: '30px' }} alt="" className="user_img me-2 position-relative z-2 mt-2" src={testimonials_img1} /> */}
                <img style={{ width: '30px', height: '30px' }} alt="" className="user_img me-2 position-relative z-2 mt-2 object-fit-cover" src={post?.coach_profile ? process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH + post?.profile_pic : blog_card_img1}  onError={(e) => defaultUserImage(e)} />
                
                <div className="d-flex justify-content-between align-items-start w-100 comment-title p-2 rounded m-0">
                    <div className="comments lh-sm">
                        <b className="fs-14 fw-500 d-block">{post.name}</b>
                        <p className="m-0 fs-10 d-inline-block text-secondary">{post.comments}</p>
                    </div>
                    <div>
                        <svg width="20" height="20" className="mt-1" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.6073 22.5423C-9.7256 9.64577 6.60771 -4.35423 13.6073 4.4985C20.6077 -4.35423 36.941 9.64577 13.6073 22.5423Z" stroke="#964B00" stroke-width="3"></path></svg>
                    </div>
                </div>
                <div className="ms-2 mt-2">
                    <svg width="20px" height="20px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#555" class="bi bi-three-dots"><path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path></svg>
                </div>
            </div>
            ))
            :''
        }
        </>
    );
}

export default SocialFeedComment;
