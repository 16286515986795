import React, { useState, useEffect } from "react";
import testimonials_img1 from "./../../../assets/images/dummy/testimonials_img1.png";
import shortvideo from "./../../../assets/images/shortvideo.png";
import blog_card_img1 from "./../../../assets/images/dummy/blog_card_img1.png";
import Lottie from "react-lottie";
import noDataLottie from "../../../assets/lottieFiles/noData.json";
import shortsVideo from "./../../../assets/images/sort.mp4";
import { shortListComment, shortListHome } from "./../../../utils/api";
import { Col } from "react-bootstrap";
const ShortVideos = () => {
  const [commentList, setCommentList] = useState([]);
  const [alltags, setAllTags] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(2);
  const [shortVideo, setShortVideo] = useState([]);
  const [lastVideo, setLastVideo] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noDataLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    shortList(pageNumber);
  }, []);
  const shortList = async (number) => {
    setShortVideo([]);
    const dataa = {
      pageNumber: number,
      pageLimit: pageLimit,
    };
    const data = await shortListHome(dataa);
    // console.log(data.data.data, "dddddddddddd");
    if (data) {
      if (data.data.data.length === 0) {
        setLastVideo(true);
      }
      setShortVideo(data.data.data);
      let firstvideo = data.data.data;
      if (firstvideo[0]) {
        if (firstvideo[0].tags) {
          const dataString = firstvideo[0].tags;
          const dataArray = dataString.split(",");
          setAllTags(dataArray);
        }
        const Commentdata = await shortListComment(
          firstvideo[0].short_video_id
        );
        // console.log(Commentdata.data.data, 'ssssssssssss');
        setCommentList(Commentdata.data.data);
      } else {
        // shortList(1);
        // setPageNumber(1);
      }
    }
  };
  const handleVideoEnded = () => {
    // console.log("called");
    if (lastVideo === false) {
      shortList(pageNumber + 1);
      setPageNumber(pageNumber + 1);
    }
  };
  const defaultUserImage = (e) => {
    e.target.src = "https://api.vaffix.com/uploads/images/avatar_dummy.webp";
    // e.target.src = blog_card_img1;
  };
  return (
    <>
      {/* {console.log(shortVideo)} */}
      {shortVideo && shortVideo.length > 0 ? (
        <>
          <Col xs="6" md="3" sm="5" lg="3">
            <div
              className="position-relative bg-black w-100"
              style={{ minHeight: "380px" }}
            >
              {/* <img alt="" className="w-100" src={shortvideo} /> */}
              {/* <video width="320" height="240" controls> */}
              {/* <video className="w-100" height="500"> */}
              {/* <video className="w-100" autoPlay muted playsInline preload="auto" onEnded={handleVideoEnded}>
                                    <source src={process.env.REACT_APP_COACH_SHORT_VIDEO_PATH + shortVideo[0].file_url} type="video/mp4" />
                                 
                                </video> */}

              <video
                className="w-100"
                autoPlay
                muted
                playsInline
                preload="auto"
                onEnded={handleVideoEnded}
                onError={(e) => {
                  e.target.src = "path_to_fallback_video.mp4";
                }}
              >
                <source
                  src={
                    process.env.REACT_APP_COACH_SHORT_VIDEO_PATH +
                    shortVideo[0]?.file_url
                  }
                  type="video/mp4"
                />
              </video>
              <div className="position-absolute top-0 p-2">
                <div className="position-relative d-flex align-items-center user-info">
                  {shortVideo[0]?.coach_profile ? (
                    <img
                      src={
                        process.env.REACT_APP_SOCKET_URL +
                        shortVideo[0]?.coach_profile
                      }
                      alt=""
                      width={50}
                      height={50}
                      className="user_img me-2 position-relative z-2"
                      onError={(e) => defaultUserImage(e)}
                    />
                  ) : (
                    <img
                      width={50}
                      height={50}
                      alt=""
                      className="user_img me-2 position-relative z-2"
                      src={testimonials_img1}
                    />
                  )}
                  <div className="user-name">
                    <span className="text-white">
                      {shortVideo[0]?.coach_name}
                    </span>
                  </div>
                </div>
                <div className="text-white fs-12 mt-2">
                  {/* {console.log(alltags)} */}
                  {alltags && alltags.length > 0
                    ? alltags.map((post) => <span>#{post} </span>)
                    : ""}
                </div>
              </div>
              {/* <div className="position-absolute play-button">
                                    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.2616 14.262C19.1486 14.378 18.7219 14.874 18.3244 15.282C15.9942 17.848 9.91525 22.048 6.73356 23.33C6.25037 23.536 5.02874 23.972 4.37604 24C3.75061 24 3.15441 23.856 2.58548 23.564C1.87628 23.156 1.30736 22.514 0.995616 21.756C0.794934 21.23 0.483195 19.656 0.483195 19.628C0.171456 17.906 0 15.108 0 12.016C0 9.07 0.171456 6.386 0.426693 4.638C0.455918 4.61 0.767657 2.654 1.10862 1.984C1.73405 0.76 2.95567 0 4.26303 0H4.37604C5.22747 0.03 7.01802 0.79 7.01802 0.818C10.0283 2.102 15.9669 6.096 18.3536 8.75C18.3536 8.75 19.0258 9.432 19.3181 9.858C19.774 10.47 20 11.228 20 11.986C20 12.832 19.7448 13.62 19.2616 14.262Z" fill="#00504E" />
                                    </svg>
                                </div> */}
              <div className="position-absolute bottom-0 p-2 w-100">
                <div className="row align-items-center">
                  <div className="col-10 comment-section">
                    {commentList && commentList.length > 0
                      ? commentList.map((post) => (
                          <div className="d-flex mb-3">
                            {post.profile_pic ? (
                              <img
                                width={35}
                                height={35}
                                alt=""
                                className="user_img me-2 mt-2 rounded-circle"
                                src={post.profile_pic}
                                onError={(e) => defaultUserImage(e)}
                              />
                            ) : (
                              <img
                                width={35}
                                height={35}
                                alt=""
                                className="user_img me-2 mt-2 rounded-circle"
                                src="https://api.vaffix.com/uploads/images/avatar_dummy.webp"
                              />
                            )}
                            <div className="text-white">
                              <span className="fs-12 fw-700 d-block">
                                {post.name}
                              </span>
                              <small className="fs-10 d-block lh-sm">
                                {post.comments}
                              </small>
                            </div>
                          </div>
                        ))
                      : ""}
                    {/* <div className="d-flex mb-3">
                                        <img width={35} height={35} alt="" className="user_img me-2 mt-2" src={testimonials_img1} />
                                        <div className="text-white">
                                            <span className="fs-16 d-block">John Borino</span>
                                            <small className="fs-12 d-block lh-sm">What led you to this thought?</small>
                                        </div>
                                    </div>
                                    <div className="d-flex mb-3">
                                        <img width={35} height={35} alt="" className="user_img me-2 mt-2" src={testimonials_img1} />
                                        <div className="text-white">
                                            <span className="fs-16 d-block">John Borino</span>
                                            <small className="fs-12 d-block lh-sm">What led you to this thought?</small>
                                        </div>
                                    </div> */}
                  </div>
                  <div className="col-2 p-0 like-comment-btns">
                    <div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 28 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.6073 22.5423C-9.7256 9.64577 6.60771 -4.35423 13.6073 4.4985C20.6077 -4.35423 36.941 9.64577 13.6073 22.5423Z"
                          stroke="#fff"
                          stroke-width="3"
                        ></path>
                      </svg>
                    </div>
                    <div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 29 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24.6074 14.3962C24.6114 15.936 24.2517 17.4551 23.5574 18.8295C22.7343 20.4766 21.4688 21.8619 19.9028 22.8304C18.3368 23.7988 16.532 24.3121 14.6908 24.3129C13.1509 24.3169 11.6319 23.9571 10.2574 23.2629L3.60742 25.4795L5.82409 18.8295C5.12984 17.4551 4.77007 15.936 4.77409 14.3962C4.7748 12.5549 5.28814 10.7502 6.25659 9.18417C7.22505 7.61815 8.61039 6.35269 10.2574 5.52953C11.6319 4.83528 13.1509 4.47551 14.6908 4.47953H15.2741C17.7058 4.61368 20.0026 5.64008 21.7248 7.36219C23.4469 9.08431 24.4733 11.3811 24.6074 13.8129V14.3962Z"
                          stroke="#fff"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>
                    <div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.1068 19.9808C17.1068 22.7401 14.8674 24.9795 12.1082 24.9795H5.11C2.35073 24.9795 0.111328 22.7401 0.111328 19.9808V12.9827C0.111328 10.2234 2.35073 7.98399 5.11 7.98399H6.10974C6.65959 7.98399 7.10947 8.43387 7.10947 8.98373C7.10947 9.53358 6.65959 9.98346 6.10974 9.98346H5.11C3.46044 9.98346 2.1108 11.3331 2.1108 12.9827V19.9808C2.1108 21.6304 3.46044 22.98 5.11 22.98H12.1082C13.7577 22.98 15.1074 21.6304 15.1074 19.9808C15.1074 19.431 15.5572 18.9811 16.1071 18.9811C16.6569 18.9811 17.1068 19.431 17.1068 19.9808ZM23.2352 6.87429L17.8266 1.28577C17.4467 0.885874 16.8069 0.875876 16.417 1.26577C16.0271 1.65567 16.0071 2.2855 16.397 2.6754L21.5357 7.974H13.1079C10.3486 7.974 8.10921 10.2134 8.10921 12.9727V17.9713C8.10921 18.5212 8.55909 18.9711 9.10894 18.9711C9.6588 18.9711 10.1087 18.5212 10.1087 17.9713V12.9727C10.1087 11.3231 11.4583 9.97347 13.1079 9.97347H21.5257L16.387 15.2721C16.0071 15.672 16.0071 16.3018 16.407 16.6817C16.597 16.8716 16.8569 16.9616 17.1068 16.9616C17.3568 16.9616 17.6267 16.8616 17.8266 16.6617L23.2252 11.0932C24.3949 9.92348 24.3949 8.02398 23.2352 6.86429V6.87429Z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs="3" md="2" sm="3" lg="2">
            <div className="position-relative bg-black w-100">
              {/* <img alt="" className="w-100" src={shortvideo} /> */}

              <video
                className="w-100"
                height="250"
                autoPlay
                muted
                playsInline
                preload="auto"
              >
                {/* <video className="w-100" height="250"> */}
                <source
                  src={
                    process.env.REACT_APP_COACH_SHORT_VIDEO_PATH +
                    shortVideo[1]?.file_url
                  }
                  type="video/mp4"
                />
                {/* <source src={shortsVideo} type="video/mp4" /> */}
              </video>
              <div className="position-absolute top-0 p-1 w-100">
                <div className="position-relative d-flex align-items-center user-info">
                  {/* <img width={30} height={30} alt="" className="user_img me-2 position-relative z-2" src={testimonials_img1} /> */}
                  {shortVideo[1]?.coach_profile ? (
                    <img
                      src={
                        process.env.REACT_APP_SOCKET_URL +
                        shortVideo[1]?.coach_profile
                      }
                      alt=""
                      width={30}
                      height={30}
                      className="user_img me-2 position-relative z-2"
                      onError={(e) => defaultUserImage(e)}
                    />
                  ) : (
                    <img
                      width={30}
                      height={30}
                      alt=""
                      className="user_img me-2 position-relative z-2"
                      src={testimonials_img1}
                    />
                  )}
                  {/* <div className="user-name ps-4 py-1">
                                            <span className="text-white fs-10">Ben Stone</span>
                                        </div> */}
                  <div className="user-name ps-4 py-1 ms-2">
                    <span className="text-white">
                      {shortVideo[1]?.coach_name}
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="position-absolute play-button">
                                    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.2616 14.262C19.1486 14.378 18.7219 14.874 18.3244 15.282C15.9942 17.848 9.91525 22.048 6.73356 23.33C6.25037 23.536 5.02874 23.972 4.37604 24C3.75061 24 3.15441 23.856 2.58548 23.564C1.87628 23.156 1.30736 22.514 0.995616 21.756C0.794934 21.23 0.483195 19.656 0.483195 19.628C0.171456 17.906 0 15.108 0 12.016C0 9.07 0.171456 6.386 0.426693 4.638C0.455918 4.61 0.767657 2.654 1.10862 1.984C1.73405 0.76 2.95567 0 4.26303 0H4.37604C5.22747 0.03 7.01802 0.79 7.01802 0.818C10.0283 2.102 15.9669 6.096 18.3536 8.75C18.3536 8.75 19.0258 9.432 19.3181 9.858C19.774 10.47 20 11.228 20 11.986C20 12.832 19.7448 13.62 19.2616 14.262Z" fill="#00504E" />
                                    </svg>
                                </div> */}
              <div className="position-absolute bottom-0 p-2 w-100">
                <div className="row align-items-end">
                  <div className="col-8 comment-section"></div>
                  <div className="col-4 like-comment-btns p-0">
                    <div className="mb-1">
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 28 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.6073 22.5423C-9.7256 9.64577 6.60771 -4.35423 13.6073 4.4985C20.6077 -4.35423 36.941 9.64577 13.6073 22.5423Z"
                          stroke="#fff"
                          stroke-width="3"
                        ></path>
                      </svg>
                    </div>
                    <div className="mb-1">
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 29 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24.6074 14.3962C24.6114 15.936 24.2517 17.4551 23.5574 18.8295C22.7343 20.4766 21.4688 21.8619 19.9028 22.8304C18.3368 23.7988 16.532 24.3121 14.6908 24.3129C13.1509 24.3169 11.6319 23.9571 10.2574 23.2629L3.60742 25.4795L5.82409 18.8295C5.12984 17.4551 4.77007 15.936 4.77409 14.3962C4.7748 12.5549 5.28814 10.7502 6.25659 9.18417C7.22505 7.61815 8.61039 6.35269 10.2574 5.52953C11.6319 4.83528 13.1509 4.47551 14.6908 4.47953H15.2741C17.7058 4.61368 20.0026 5.64008 21.7248 7.36219C23.4469 9.08431 24.4733 11.3811 24.6074 13.8129V14.3962Z"
                          stroke="#fff"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </div>
                    <div className="mb-1">
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.1068 19.9808C17.1068 22.7401 14.8674 24.9795 12.1082 24.9795H5.11C2.35073 24.9795 0.111328 22.7401 0.111328 19.9808V12.9827C0.111328 10.2234 2.35073 7.98399 5.11 7.98399H6.10974C6.65959 7.98399 7.10947 8.43387 7.10947 8.98373C7.10947 9.53358 6.65959 9.98346 6.10974 9.98346H5.11C3.46044 9.98346 2.1108 11.3331 2.1108 12.9827V19.9808C2.1108 21.6304 3.46044 22.98 5.11 22.98H12.1082C13.7577 22.98 15.1074 21.6304 15.1074 19.9808C15.1074 19.431 15.5572 18.9811 16.1071 18.9811C16.6569 18.9811 17.1068 19.431 17.1068 19.9808ZM23.2352 6.87429L17.8266 1.28577C17.4467 0.885874 16.8069 0.875876 16.417 1.26577C16.0271 1.65567 16.0071 2.2855 16.397 2.6754L21.5357 7.974H13.1079C10.3486 7.974 8.10921 10.2134 8.10921 12.9727V17.9713C8.10921 18.5212 8.55909 18.9711 9.10894 18.9711C9.6588 18.9711 10.1087 18.5212 10.1087 17.9713V12.9727C10.1087 11.3231 11.4583 9.97347 13.1079 9.97347H21.5257L16.387 15.2721C16.0071 15.672 16.0071 16.3018 16.407 16.6817C16.597 16.8716 16.8569 16.9616 17.1068 16.9616C17.3568 16.9616 17.6267 16.8616 17.8266 16.6617L23.2252 11.0932C24.3949 9.92348 24.3949 8.02398 23.2352 6.86429V6.87429Z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </>
      ) : (
        <Col>
          <Lottie
            options={defaultOptions}
            height={300}
            width={400}
            isStopped={false}
            isPaused={false}
          />
        </Col>
      )}
    </>
  );
};

export default ShortVideos;
