

export const Events = [
  {
    event_id: 1,
    user: "Jatin Mishra",
    u_id: "EICC25421",
    title: "Event 1",
    subtitle: "Emotional Intelligence complete course",
    avatar: "https://picsum.photos/200/300",
    status: "Completed",
    start: new Date(new Date(new Date().setHours(8)).setMinutes(0)),
    end: new Date(new Date(new Date().setHours(10)).setMinutes(0)),
    disabled: true,
    admin_id: [1, 2, 3, 4],
    color: "#CCDCDC"
  },
  {
    event_id: 2,
    user: "Jatin Mishra",
    u_id: "EICC25421",
    title: "Event 2",
    subtitle: "Emotional Intelligence complete course",
    avatar: "https://picsum.photos/200/300",
    status: "Cancelled",
    start: new Date(new Date(new Date().setHours(10)).setMinutes(0)),
    end: new Date(new Date(new Date().setHours(12)).setMinutes(0)),
    admin_id: 2,
    color: "#00504E"
  },
  {
    event_id: 3,
    user: "Jatin Mishra",
    u_id: "EICC25421",
    title: "Event 3",
    subtitle: "Emotional Intelligence complete course",
    avatar: "https://picsum.photos/200/300",
    status: "Completed",
    start: new Date(
      new Date(new Date(new Date().setHours(9)).setMinutes(30)).setDate(
        new Date().getDate() - 1
      )
    ),
    end: new Date(
      new Date(new Date(new Date().setHours(12)).setMinutes(0)).setDate(
        new Date().getDate()  - 1
      )
    ),
    admin_id: 1,
    color: "#C1000E"
  },
  {
    event_id: 4,
    user: "Jatin Mishra",
    u_id: "EICC25421",
    title: "Event 4",
    subtitle: "Emotional Intelligence complete course",
    avatar: "https://picsum.photos/200/300",
    status: "Completed",
    start: new Date(
      new Date(new Date(new Date().setHours(9)).setMinutes(30)).setDate(
        new Date().getDate() - 2
      )
    ),
    end: new Date(
      new Date(new Date(new Date().setHours(11)).setMinutes(0)).setDate(
        new Date().getDate() - 2
      )
    ),
    admin_id: 2,
    color: "#DADADA"
  },
  ];
  
 
  