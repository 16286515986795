import React, {useEffect} from "react";
import {Button, Col, Container,Form ,NavLink,Row } from "react-bootstrap";
import icy_williams from '../../../assets/images/icy_williams.jpg';
import icy_williams_sign from '../../../assets/images/icy_williams_sign.png';
import settings_personalization from '../../../assets/images/icons/settings_personalization.png';
import inclusivity from '../../../assets/images/icons/inclusivity.png';
import community from '../../../assets/images/icons/community.png';

import browse_coach from '../../../assets/images/icons/browse_coach.png';
import activity_stream from '../../../assets/images/icons/activity_stream.png';
import become_a_coach from '../../../assets/images/icons/become_a_coach.png';
import blog from '../../../assets/images/icons/blog.png';
import support from '../../../assets/images/icons/support.png';
import {Link } from "react-router-dom"


function AboutComponent(){
    useEffect(() => {
        
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
          link = document.createElement('link');
          link.setAttribute('rel', 'canonical');
          document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
    
      }, []);
    return(
        <>
        <section className="brand_bg_color1 brand_patten_bg_color1 py-5">
            <Container>
                <Row className="g-4">
                    <Col sm="12" className="">
                        <div className="text-center hdng_a">
                            <h6 className="text-uppercase">About Qoach</h6>
                            <p className="fs-16 fw-500">At Qoach.io, we redefine coaching by serving as a comprehensive marketplace where individuals seeking personal growth and professional development can find their ideal coach. We specialize in matching you with coaches who are not only experts in their fields but also resonate with your specific needs.</p>
                        </div>
                    </Col>

                    <Col sm="12">
                        <div className="our_vision_mission_card">
                            <div className="d-flex align-items-center">
                                <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.3906 36.4824H6.9331C5.74434 36.4824 4.78125 37.4455 4.78125 38.6343V48.3196C4.78125 49.5083 5.74535 50.4714 6.9331 50.4714H13.3896C14.5784 50.4714 15.5425 49.5073 15.5425 48.3196V38.6343C15.5425 37.4455 14.5784 36.4824 13.3906 36.4824Z" fill="#964B00"/>
                                    <path d="M30.0771 28.9482H23.6206C22.4318 28.9482 21.4688 29.9123 21.4688 31.1021V48.3199C21.4688 49.5086 22.4328 50.4717 23.6206 50.4717H30.0771C31.2659 50.4717 32.23 49.5076 32.23 48.3199V31.1011C32.23 29.9123 31.2659 28.9482 30.0771 28.9482Z" fill="#964B00"/>
                                    <path d="M46.7656 20.3398H40.3091C39.1204 20.3398 38.1562 21.3039 38.1562 22.4917V48.3189C38.1562 49.5076 39.1204 50.4707 40.3091 50.4707H46.7656C47.9544 50.4707 48.9185 49.5066 48.9185 48.3189V22.4917C48.9175 21.3029 47.9554 20.3398 46.7656 20.3398Z" fill="#964B00"/>
                                    <path d="M43.5401 6.18664C44.3864 6.18664 45.0724 5.50061 45.0724 4.65435C45.0724 3.8081 44.3864 3.12207 43.5401 3.12207C42.6938 3.12207 42.0078 3.8081 42.0078 4.65435C42.0078 5.50061 42.6938 6.18664 43.5401 6.18664Z" fill="#964B00"/>
                                    <path d="M49.6246 4.15741C49.3405 3.7756 48.7995 3.69803 48.4177 3.98414L44.9059 6.6135H44.3508C44.114 6.6135 43.9075 6.71626 43.7514 6.87241L44.1533 9.82213L43.5368 10.345L42.9202 9.82213L43.3222 6.87241C43.1661 6.71626 42.9615 6.6135 42.7228 6.6135H42.1687L38.6579 3.98414C38.2771 3.69803 37.7351 3.7756 37.45 4.15741C37.1639 4.53923 37.2414 5.08021 37.6232 5.36531L41.3668 8.16694C41.5109 8.27473 41.6821 8.33417 41.8614 8.3382V12.2349L40.2677 17.812C40.1367 18.2703 40.4027 18.7489 40.8611 18.8798C40.9386 18.902 41.0192 18.9131 41.0978 18.9131C41.4736 18.9131 41.8191 18.6652 41.9269 18.2865L43.5368 12.651L45.1466 18.2865C45.2544 18.6652 45.601 18.9131 45.9767 18.9131C46.0553 18.9131 46.1359 18.902 46.2145 18.8798C46.6729 18.7489 46.9378 18.2713 46.8069 17.812L45.2131 12.2349V8.33921C45.3924 8.33518 45.5657 8.27574 45.7088 8.16795L49.4523 5.36531C49.8321 5.0792 49.9127 4.53923 49.6246 4.15741Z" fill="#964B00"/>
                                </svg>
                                <h4 className="fs-20 fw-600 ms-2 mb-0 text_brand_color2">Our Vision</h4>
                            </div>
                            <p className="fs-15 fw-500 mt-3 mb-0">At Qoach.io, our vision is to be the leading platform that empowers individuals worldwide to achieve their fullest potential through personalized coaching experiences. We envision a community where every person has access to the guidance and support they need to thrive personally and professionally.</p>
                        </div>
                    </Col>

                    <Col sm="12">
                        <div className="our_vision_mission_card">
                            <div className="d-flex align-items-center">
                                <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M58.4375 11.0887C58.25 10.3387 57.5 9.96365 56.75 9.96365H51.125V4.33865C51.125 3.58865 50.75 2.83865 50 2.65115C49.25 2.27615 48.5 2.46365 47.9375 3.02615L40.4375 10.5262C40.0625 10.9012 39.875 11.2762 39.875 11.8387V18.5887L29.1875 29.2762C28.4375 30.0262 28.4375 31.1512 29.1875 31.9012C29.5625 32.2762 30.125 32.4636 30.5 32.4636C30.875 32.4636 31.4375 32.2762 31.8125 31.9012L42.5 21.2137H49.25C49.8125 21.2137 50.1875 21.0262 50.5625 20.6512L58.0625 13.1512C58.625 12.5887 58.8125 11.8387 58.4375 11.0887Z" fill="#964B00"/>
                                    <path d="M34.4375 34.5264C33.5 35.6514 32 36.2139 30.5 36.2139C29 36.2139 27.5 35.6514 26.5625 34.5264C24.3125 32.2764 24.3125 28.7139 26.5625 26.6514L31.8125 21.4014C31.4375 21.2139 30.875 21.2139 30.5 21.2139C25.25 21.2139 21.125 25.3389 21.125 30.5889C21.125 35.8389 25.25 39.9639 30.5 39.9639C35.75 39.9639 39.875 35.8389 39.875 30.5889C39.875 30.2139 39.875 29.6514 39.6875 29.2764L34.4375 34.5264Z" fill="#964B00"/>
                                    <path d="M53.1875 23.2764C52.25 24.4014 50.75 24.9639 49.25 24.9639H44L42.6875 26.2764C43.25 27.5889 43.4375 29.0889 43.4375 30.5889C43.4375 37.9014 37.625 43.7139 30.3125 43.7139C23 43.7139 17.1875 37.9014 17.1875 30.5889C17.1875 23.2764 23 17.4639 30.3125 17.4639C31.8125 17.4639 33.3125 17.8389 34.625 18.2139L36.125 17.0889V11.8389C36.125 10.3389 36.6875 8.83887 37.8125 7.90137L39.6875 6.02637C36.6875 4.90137 33.6875 4.33887 30.5 4.33887C16.0625 4.33887 4.25 16.1514 4.25 30.5889C4.25 45.0264 16.0625 56.8389 30.5 56.8389C44.9375 56.8389 56.75 45.0264 56.75 30.5889C56.75 27.4014 56.1875 24.4014 55.0625 21.4014L53.1875 23.2764Z" fill="#964B00"/>
                                </svg>
                                <h4 className="fs-20 fw-600 ms-2 mb-0 text_brand_color2">Our Mission</h4>
                            </div>
                            <p className="fs-15 fw-500 mt-3 mb-0">Our mission at Qoach.io is two-fold: to empower individuals on their journey to personal and professional fulfillment through effective coaching, and to empower knowledgeable individuals to become coaches and share their expertise. We believe in the transformative power of coaching to unlock potential and drive meaningful change in people's lives. By connecting individuals with coaches who have overcome challenges similar to theirs and achieved success, we provide personalized guidance and support that inspires confidence and accelerates progress. Simultaneously, by enabling knowledgeable individuals to become coaches on our platform, we foster a community where expertise is shared and personal growth is nurtured.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        
        <section className="white_bg py-3 py-lg-5">
            <Container>
                <Row className="g-4">
                    <Col sm="6" className="mx-auto">
                        <div className="text-center hdng_a">
                            <h6 className="text-uppercase lh-15">A Message from Our Chief Coaching Officer</h6>
                        </div>
                    </Col>
                </Row>
                
            </Container>
            <Container className=" mt-4">
                <Row className="g-4">
                    <Col sm={6} lg="4" >
                        <img width="100%" src={icy_williams} alt="images" />
                        <div className="text_brand_color2 mt-3">
                            <h4 className="fs-26 fw-600 mb-0">Warm regards,</h4>
                            <h5 className="fs-18 fw-600 mb-3">Icy Williams</h5>
                            <h5 className="fs-15 fw-500 mb-2">Chief Coaching Officer, Qoach.Io</h5>
                            <img height="50" src={icy_williams_sign} alt="images" />
                        </div>
                    </Col>
                    <Col>
                        <div className="text_brand_color2">
                            <p className="fs-15 fw-500">Welcome to Qoach.io,</p>
                            <p className="fs-15 fw-500">I'm honored to introduce you to our platform dedicated to your personal growth and professional development. At Qoach.io, our mission is deeply rooted in empowering individuals like you to unleash your full potential.</p>
                            <p className="fs-15 fw-500">Throughout my career as a coach, I've witnessed firsthand the transformative impact of personalized coaching. It's not just about achieving goals; it's about the journey of discovery, resilience, and growth. Our team of dedicated coaches at Qoach.io is committed to guiding you through this journey, providing you with the tools, insights, and support you need to succeed.</p>
                            <p className="fs-15 fw-500">I believe in the power of connection – connecting you with coaches who not only possess the expertise you seek but also understand your unique challenges. Whether you're navigating career transitions, seeking to enhance your leadership skills, or striving for personal balance, Qoach.io is here to match you with coaches who will inspire and empower you.</p>
                            <p className="fs-15 fw-500">Our vision is to create a community where coaching is not just a service but a partnership in your journey towards fulfillment and success. Together, we can achieve remarkable things.</p>
                            <p className="fs-15 fw-500">I invite you to explore all that Qoach.io has to offer, from our diverse range of expert coaches to our innovative coaching methodologies. Let's collaborate to turn your goals into reality and create a future where you thrive both personally and professionally.</p>
                        </div>
                    </Col>
                    {/* <Col sm="12">
                        <Row className="align-items-end mt-3 g-4">
                            <Col xs="auto" className="text_brand_color2">
                                
                            </Col>
                        </Row>
                    </Col> */}
                </Row>
            </Container>
        </section>

        <section className="brand_bg_color2 py-5">
            <Container>
                <h3 className="fs-40 fw-700 text-white text-center">Our Core Values</h3>
                <Row className="g-4 mt-2">
                    <Col md="4">
                        <div className="core_values_">
                            <img src={settings_personalization} alt="images" />
                            <div className="ms-3">
                                <h4>Precision and Personalization</h4>
                                <p>We prioritize matching you with the coach who best fits your unique needs.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="core_values_">
                            <img src={inclusivity} alt="images" />
                            <div className="ms-3">
                                <h4>Inclusivity</h4>
                                <p>We believe that everyone with expertise can be a coach, regardless of formal coaching experience or qualifications.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="core_values_">
                            <img src={community} alt="images" />
                            <div className="ms-3">
                                <h4>Community</h4>
                                <p>We foster a supportive community where users and coaches can thrive together.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        
        <section className="white_bg py-3 py-lg-5">
            <Container>
                <h3 className="fs-40 fw-700 text_brand_color2 text-center">Our Approach</h3>
            </Container>
            <Container className=" mt-4">
                <Row className="g-4">
                    <Col sm="12">
                        <div className="text_brand_color2">
                            <p className="fs-15 fw-500">At Qoach.io, our approach is client-centered and results-driven. We recognize each individual's uniqueness, with distinct goals and challenges. Our platform utilizes advanced matching algorithms and personal consultations to pair you with coaches perfectly suited to your needs. These coaches not only possess the necessary expertise but also share a deep understanding of your journey, serving as effective mentors and guides.</p>
                        
                            <p className="fs-15 fw-500">Imagine connecting with a coach who has triumphed over similar challenges to achieve personal success. These coaches resonate with your experiences, offering supportive guidance akin to a trusted neighbor. Whether advancing your career, enhancing personal well-being, or tackling new challenges, Qoach.io connects you with a coach perfectly aligned with your unique situation. Our approach ensures personalized support tailored to your goals, fostering an effective and transformative coaching experience.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className="brand_bg_color2 py-5">
            <Container>
                <h3 className="fs-40 fw-700 text-white text-center">Our Services</h3>
                <Row className="g-4 mt-2 justify-content-center">
                    <Col md="4">
                        <div className="core_values_">
                            <img src={browse_coach} alt="images" />
                            <div className="ms-3">
                                <h4>Browse Coach</h4>
                                <p>Discover a diverse range of coaches across various fields.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="core_values_">
                            <img src={activity_stream} alt="images" />
                            <div className="ms-3">
                                <h4>Activity Stream</h4>
                                <p>Stay updated with the latest activities and sessions from our coaches.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="core_values_">
                            <img src={become_a_coach} alt="images" />
                            <div className="ms-3">
                                <h4>Become a Coach</h4>
                                <p>Share your expertise and help others succeed.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="core_values_">
                            <img src={blog} alt="images" />
                            <div className="ms-3">
                                <h4>Blogs</h4>
                                <p>Read a rich collection of knowledge, experiences, and life lessons from our coaches and mentors.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="core_values_">
                            <img src={support} alt="images" />
                            <div className="ms-3">
                                <h4>Support</h4>
                                <p>Access comprehensive support for all your coaching needs.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>


        <section className="become_find_coach d-sm-block">
            <Container fluid className="">
                <Row className="justify-content-between align-items-center">
                    <Col md="6">
                        <div className="position-relative">
                            <div className="about_find_coach_bg">
                                <p className="fs-15 fw-500 text-white">We let coaches offer live sessions, giving you a chance to experience your coach’s approach and expertise firsthand. This way, you can ensure that your coach is the right fit for your specific needs.</p>
                                <Form>
                                    <Form.Group as={Row} className="mt-3">
                                        <Col className="d-grid col-12">
                                        <Link to={"/browse-coach/list-view"}>
                                            <Button variant="rounded_success" className="my-3 py-4">Find Coach</Button>
                                        </Link>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="about_be_a_coach_bg">
                            <h4 className="mb-3">Become Coach</h4>
                            <p className=" fs-15 fw-500">
                                Empowerment Coach: Guiding Individuals to Unlock Their Full
                                Potential and Achieve Personal Success.
                            </p>
                            <Form>
                                <Form.Group as={Row} className="mt-3">
                                    <Col className="d-grid col-12">
                                        <Link to={"/become-a-coach"}>
                                        <Button variant="rounded_success" className="my-3 py-4">Become a Coach</Button>
                                        </Link>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

    </>

    )
}

export default AboutComponent;