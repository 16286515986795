import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import Modal from "react-awesome-modal";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import testimonials_img1 from "../../../assets/images/dummy/testimonials_img1.png";
import live_session_img1 from "../../../assets/images/dummy/live_session_img1.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import view from "../../../assets/images/icons/view.png";
import AgoraUIKit, { layout } from "agora-react-uikit";
import {
  createSession,
  coachSessionList,
  editSession,
  getMyFollowings,
  myContacts,
  changeSessionStatus,
  getSessionUsers,
  deleteSession,
} from "../../../utils/api";
import EditIcon from "../../../assets/images/EditIcon.png";
import DeleteIcon from "../../../assets/images/delete.png";
import {
  UserContextProvider,
  useUserContext,
} from "../../../utils/hooks/UserContext";
import { toast } from "react-toastify";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";

import AgoraComponent from "./../../../Front/Pages/agora/livestream";

function CoachMyActivityLiveSessionComponent() {
  const { user } = useUserContext();
  const [showModal, setShowModal] = useState(false);
  const [allSessions, setAllSessions] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentTime, setCurrentTime] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [singleSessionDetails, setSingleSessionDetails] = useState({});
  const [allFollowers, setAllFollowers] = useState([]);
  const [poster_url, setPosterUrl] = useState(null);
  const [name, setName] = useState("");
  const [duration, setDuration] = useState("1");
  const [image, setImage] = useState([]);
  const [tags, setTags] = useState("");
  const [session_id, setSessionId] = useState("");
  const [audience_id, setAudienceId] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [selectedAudience, setSelectedAudience] = useState([]);
  const [sessionDetails, setSessionDetails] = useState({});
  const [videoCallSessionId, setVideoCallSessionId] = useState("");
  const [videoCallSessionName, setVideoCallSessionName] = useState("");
  const [allInterestedUsers, setAllIntrestedUsers] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);

  // AGORA Hooks
  const [channelName, setChanelName] = useState("");
  const [agoraToken, setAgoratoken] = useState("");
  const [videoCall, setVideocall] = useState(false);
  const [rtmToken, setRtmToken] = useState("");

  const app_id = "890c456575c247c7aab98108ca312bad";

  useEffect(() => {
    const canonicalUrl = window.location.origin + window.location.pathname;
    let link = document.querySelector("link[rel='canonical']");
    if (!link) {
      link = document.createElement("link");
      link.setAttribute("rel", "canonical");
      document.head.appendChild(link);
    }
    link.setAttribute("href", canonicalUrl);

    window.scrollTo(0, 0);
    getData();
    const now = new Date();
    setCurrentTime(formatTime(now));
    getMyFollowers();
  }, []);

  const getData = async () => {
    const formData = new FormData();
    formData.append("coach_id", user.coach_id);
    const { data } = await coachSessionList(formData);
    // console.log(data);
    setAllSessions(data.data);
  };

  const getMonth = (date) => {
    let months = [
      "January",
      "Febuary",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[new Date(date).getMonth()];
  };

  const formatTime = (time) => {
    const date = new Date(time);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
  };

  const handleEditForm = (post) => {
    // console.log(post);
    // console.log(new Date(post.start_date));
    setSelectedDate(new Date(post.start_date));
    setTime(
      `${new Date(post.start_date).getHours()}:${new Date(
        post.start_date
      ).getMinutes()}`
    );
    setName(post.session_name);
    setSessionId(post?.id);
    setDuration(post?.duration);
    setTags(post?.tags);
    setImageUrl(process.env.REACT_APP_BASE_URL + post?.poster_url);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };
  const handleDateChange = (date) => {
    // console.log(date);
    setSelectedDate(date);
    setTime(`${new Date(date).getHours()}:${new Date(date).getMinutes()}`);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const openLiveSession = () => {};

  const handleHideModal = () => {
    setShowModal(false);
  };

  const [time, setTime] = useState("");
  // const [error, setError] = useState('');

  const handleChange = (event) => {
    // console.log(event.target.value);
    setTime(event.target.value);
  };

  // const handleSubmit = () => {
  //     const regex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9] (AM|PM)$/;
  //     if (!regex.test(time)) {
  //         setError('Invalid time format');
  //     } else {
  //         // Submit the form
  //     }
  // };

  const dateConversion = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  const onSubmitHandler = async () => {
    // console.log("function called");
    try {
      const start_end = new Date(selectedDate);
      start_end.setHours(start_end.getHours() + parseInt(duration));
      const formData = new FormData();
      formData.append("time", selectedTime);
      formData.append("start_date", dateConversion(selectedDate));
      formData.append("start_end", dateConversion(start_end));
      formData.append("session_name", name);
      formData.append("duration", duration + " Hour");
      formData.append("tags", tags);
      formData.append("status", "Active");
      formData.append("coach_id", user.coach_id);
      formData.append("poster_url", poster_url);
      formData.append("audience_id", audience_id);
      const { data } = await createSession(formData);
      if (data?.status) {
        toast.success(data?.message);
        setShowModal(false);
        setSelectedTime(null);
        setSelectedDate(new Date());
        setName("");
        setDuration("1");
        setTags("");
        getData();
      } else {
        toast.error(data?.message || "Something went wrong");
      }
      // console.log(data.message);
    } catch (error) {
      // toast.error(error?.response?.data?.message || error?.message)
    } finally {
      // setisLoading(false)
    }
  };

  const submitEditForm = async () => {
    try {
      const start_end = new Date(selectedDate);
      start_end.setHours(start_end.getHours() + parseInt(duration));
      const formData = new FormData();
      formData.append("time", selectedTime);
      formData.append("start_date", dateConversion(selectedDate));
      formData.append("start_end", dateConversion(start_end));
      formData.append("session_name", name);
      formData.append("duration", duration + " Hour");
      formData.append("tags", tags);
      formData.append("status", "Active");
      formData.append("coach_id", user.coach_id);
      formData.append("poster_url", poster_url);
      formData.append("session_id", session_id);
      const { data } = await editSession(formData);
      if (data?.status) {
        toast.success(data?.message);
        setShowModal(false);
        setSelectedTime(null);
        setSelectedDate(new Date());
        setName("");
        setDuration("1");
        setTags("");
        getData();
      } else {
        toast.error(data?.message || "Something went wrong");
      }
      // console.log(data.message);
    } catch (error) {
      // toast.error(error?.response?.data?.message || error?.message)
    } finally {
      // setisLoading(false)
    }
  };

  // const completeSession = async () => {
  //   setVideocall(false);
  // };
  // let props = {
  //   rtcProps: {
  //     appId: app_id,
  //     channel: "",
  //     token: "",
  //   },
  //   callbacks: {
  //     EndCall: completeSession,
  //   },
  // };

  const getMyFollowers = async () => {
    const data = await myContacts();
    // console.log(data);
    if (data) {
      setAllFollowers(data.data.data);
    }
  };

  const updateSession = async (id) => {
    const payload = {
      session_status: "live",
      id,
    };
    await changeSessionStatus(payload);
  };

  const generateToken = async (id, name, data) => {
    // console.log(data);
    await updateSession(id);
    setVideoCallSessionId(id);
    setVideoCallSessionName(name);
    setSessionDetails(data);
    setVideocall(true);
    // }
  };

  const getInterestedUsers = async (id) => {
    setShowUserModal(true);
    const data = await getSessionUsers({ session_id: id });
    // console.log(data);
    setAllIntrestedUsers(data.data.data);
  };

  const handleSessionDelete = (id) => {
    try {
      deleteSession({ session_id: id })
        .then((data) => {
          console.log(data);
          toast.success("Deleted successfully!");
          setDeleteModal(false);
          getData();
        })
        .catch((err) => {
          toast.error("Something went wrong!");
          setDeleteModal(false);
          getData();
        });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
      setDeleteModal(false);
      getData();
    }
  };
  return (
    <>
      <section className="">
        <Row className="justify-content-center">
          <Col md="10 mb-3">
            {/* <div className="card brand_bg_color4 p-3"> */}
            <div className="card p-3">
              <div className="d-flex align-items-center justify-content-center">
                <div class="w-100 me-5">
                  <button
                    className="w-100 btn secondary-button"
                    onClick={openLiveSession}
                  >
                    Host a live session
                  </button>
                </div>
                <div className="w-50">
                  <NavLink
                    to={{
                      pathname: "/coach/my-activities/create",
                    }}
                  >
                    <button
                      className="w-100 btn primary-button"
                      // onClick={}
                    >
                      Schedule Now
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="9">
            <Row className="justify-content-between g-3">
              {allSessions && allSessions.length > 0 ? (
                allSessions.map((post) => (
                  <Col sm="6">
                    <NavLink>
                      <div className="frlv_session_card">
                        <img
                          alt=""
                          className="live_session_img"
                          width="100%"
                          height="500px"
                          style={{
                            objectFit: "contain",
                          }}
                          src={
                            post.poster_url === "" || post.poster_url === null
                              ? live_session_img1
                              : process.env.REACT_APP_BASE_URL + post.poster_url
                          }
                        />
                        <div className="frlv_session_ff_column">
                          <div className="frlv_session_card_hdr">
                            <h4 className="fs-14 fw-500 m-0 mb-2">
                              {post.session_name}
                            </h4>
                            <p className="fs-10 fw-500 m-0">
                              {post?.start_date.substring(8, 10)}{" "}
                              {getMonth(post.start_date)}’
                              {post?.start_date.substring(2, 4)} @{" "}
                              {post?.start_date.substring(11, 16)} -{" "}
                              {post?.start_end.substring(0, 5)} IST
                            </p>
                            <div className="session_edit_buton">
                              <img
                                alt=""
                                width="20"
                                title={"Show Users"}
                                src={view}
                                onClick={() => {
                                  getInterestedUsers(post.id);
                                }}
                              />
                              <img
                                onClick={(e) => {
                                  // setSingleSessionDetails(post)
                                  handleEditForm(post);
                                  setShowEdit(true);
                                  setShowModal(true);
                                }}
                                src={EditIcon}
                                alt="Edit Icon"
                                className="editIconSession"
                              />
                              <img
                                onClick={(e) => {
                                  // setSingleSessionDetails(post)
                                  setDeleteModal(true);
                                  setSessionDetails(post);
                                }}
                                src={DeleteIcon}
                                alt="Edit Icon"
                                className="editIconSession"
                              />
                            </div>
                          </div>
                          {/* {console.log(post)} */}
                          <Button
                            variant="cancel"
                            className="px-5"
                            onClick={() => {
                              generateToken(post?.id, post.session_name, post);
                            }}
                          >
                            {/* {console.log(post)} */}
                            Host Session
                          </Button>
                          <div className="frlv_session_card_footer">
                            <div className="d-flex align-items-center mb-2">
                              <img
                                width="35"
                                height="35"
                                alt=""
                                className="user_img"
                                src={
                                  user?.profile_pic
                                    ? user.login_type === "Linkedin"
                                      ? user?.profile_pic
                                      : process.env
                                          .REACT_APP_COACH_PROFILE_FOLDER_PATH +
                                        user?.profile_pic
                                    : testimonials_img1
                                }
                              />
                              <div className="ms-2">
                                <h6 className="fs-16 fw-500 m-0">
                                  {user?.name}
                                </h6>
                              </div>
                            </div>
                            <p className="fs-10 fw-500 m-0">
                              {post.tags
                                .split(/[ ,]+/)
                                .map((tag) => `#${tag}`)
                                .join(", ")}
                              <span>+3more</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </Col>
                ))
              ) : (
                <div className="text-center my-5 pt-5">
                  <p>No live session available yet.</p>
                </div>
              )}
              {/* <Col sm="6">
                <NavLink>
                    <div className="frlv_session_card">
                        <img alt="" className="live_session_img" width="100%" src={live_session_img1} />
                        <div className="frlv_session_ff_column">
                            <div className="frlv_session_card_hdr">
                                <h4 className="fs-14 fw-500 m-0 mb-2">Upcoming Live Session</h4>
                                <p className="fs-10 fw-500 m-0">25 March’23 @ 08:00 - 09:00 IST</p>
                            </div>
                            <Button variant="cancel" className="px-5">Cancel</Button>
                            <div className="frlv_session_card_footer">
                                <div className="d-flex align-items-center mb-2">
                                    <img width="35" height="35" alt="" className="user_img" src={testimonials_img1} />
                                    <div className="ms-2">
                                        <h6 className="fs-16 fw-500 m-0">Alex Beth</h6>
                                    </div>
                                </div>
                                <p className="fs-10 fw-500 m-0">#finanace#commerce#expert <span>+3more</span></p>
                            </div>
                        </div>
                    </div>
                </NavLink>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </section>
      <Modal
        visible={showModal}
        height="90%"
        width="80%"
        effect="fadeInUp"
        onClickAway={() => setShowModal(false)}
      >
        <div className="streaming-create-modal">
          <h3>Schedule live session</h3>
          <div className="session-close" onClick={() => setShowModal(false)}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setShowModal(false)}
            >
              <path
                opacity="0.5"
                d="M7.47118 6.76256L8.00151 7.29289L8.53184 6.76256L13.8351 1.45926C14.0304 1.264 14.347 1.264 14.5422 1.45926C14.7375 1.65452 14.7375 1.97111 14.5422 2.16637L9.23895 7.46967L8.70862 8L9.23895 8.53033L14.5422 13.8336C14.7375 14.0289 14.7375 14.3455 14.5422 14.5407C14.347 14.736 14.0304 14.736 13.8351 14.5407L8.53184 9.23744L8.00151 8.70711L7.47118 9.23744L2.16788 14.5407C1.97262 14.736 1.65603 14.736 1.46077 14.5407C1.26551 14.3455 1.26551 14.0289 1.46077 13.8336L6.76407 8.53033L7.2944 8L6.76407 7.46967L1.46077 2.16637C1.26551 1.97111 1.26551 1.65452 1.46077 1.45926C1.65603 1.264 1.97262 1.264 2.16788 1.45926L7.47118 6.76256Z"
                stroke="#14183E"
                stroke-width="1.5"
              />
            </svg>
          </div>
          {/* <Modal.Body> */}
          <div className="row">
            <div className="col-md-4 text-center border-end">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                inline // This prop makes the calendar always visible
              />
            </div>
            <div className="col-md-8 ps-5">
              <div className="d-flex p-2">
                <div className="col-md-6 form-group mb-3">
                  <label class="form-label">Time</label>
                  <div>
                    <div className="d-flex align-items-center border rounded">
                      <div className="">
                        <input
                          type="time"
                          value={time}
                          onChange={handleChange}
                          className="border-0 form-control"
                        />
                      </div>
                      <div className="border">
                        <select
                          value={time.split(" ")[1]}
                          class="border-0 form-select"
                        >
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 form-group mb-3">
                  <label class="form-label">Time</label>
                  <div>
                    <div className="d-flex align-items-center border rounded">
                      <div className="">
                        <input
                          type="time"
                          value={time}
                          onChange={handleChange}
                          className="border-0 form-control"
                        />
                      </div>
                      <div className="border">
                        <select
                          value={time.split(" ")[1]}
                          class="border-0 form-select"
                        >
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-6 form-group mb-3"
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <label for="" class="form-label">
                    Session Duration
                  </label>
                  <select
                    id=""
                    class="form-select"
                    style={{ padding: "13px" }}
                    onChange={(e) => setDuration(e.target.value)}
                  >
                    <option value="1">1 Hour</option>
                    <option value="2">2 Hour</option>
                    <option value="3">3 Hour</option>
                    <option value="4">4 Hour</option>
                  </select>
                </div>
              </div>
              <div className="form-group mb-3">
                {/* <label class="form-label">Session name</label> */}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Session Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group mb-3" style={{ width: "100%" }}>
                <label for="" class="form-label">
                  Select Audience
                </label>
                {/* <MultiSelect
                  variant="rd_btn"
                  id="tbg-radio-1"
                  options={allFollowers}
                  value={selectedAudience}
                  onChange={setSelectedAudience}
                  className="select-option fs-14"
                  // filterOptions={customFilterOptions}
                  overrideStrings={{ selectSomeItems: "Select Audience" }}
                /> */}
                <select
                  id=""
                  class="form-select"
                  style={{ padding: "13px" }}
                  onChange={(e) => setAudienceId(e.target.value)}
                >
                  <option>Select Audience</option>
                  {allFollowers.length > 0 &&
                    allFollowers.map((item) => (
                      <option value={item.value}> {item.label} </option>
                    ))}
                </select>
              </div>
            </div>
            {/* <div className="col-md-12">
              <div className="form-group mb-3">
                <label class="form-label">Poster Image (Optional) </label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(e) => {
                    console.log(e.target.files[0]);
                    setImageUrl(URL.createObjectURL(e.target.files[0]));
                    setPosterUrl(e.target.files[0]);
                  }}
                />
              </div>
            </div> */}
            {imageUrl != "" && (
              <>
                <div>
                  <img src={imageUrl} height="300px" />
                </div>
              </>
            )}
            <div className="col-md-12">
              <div className="form-group mb-3">
                {/* <label class="form-label">Tags</label> */}
                <input
                  type="text"
                  placeholder="Tags"
                  className="form-control"
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12 text-center">
              <button
                className="px-5 btn primary-button"
                onClick={() => {
                  if (showEdit === false) {
                    onSubmitHandler();
                  } else {
                    submitEditForm();
                  }
                }}
              >
                Submit
              </button>
            </div>
          </div>
          {/* </Modal.Body> */}
        </div>
      </Modal>

      {videoCall ? (
        <AgoraComponent
          videoCallSessionId={videoCallSessionId}
          videoCallSessionName={videoCallSessionName}
          sessionDetails={sessionDetails}
        />
      ) : (
        ""
      )}
      {/* <Modal visible={videoCall} width="100%" height="100%">
        {videoCall === true && agoraToken !== null && (
          <div style={styles.videoContainer}>
            <AgoraUIKit
              rtcProps={{
                appId: app_id,
                channel: "testing",
                token: agoraToken,
                uid: user.coach_id ? `${user.coach_id}` : `${user?.user_id}`,
                role: "host",
                layout: layout.grid,
                // enableScreensharing: true,
                // screenshareUid: 9999,
                // screenshareToken: screenSHareToken,
                // background: backgroundImage, // Set the virtual background image
              }}
              rtmProps={{
                username: user?.name,
                displayUsername: true,
                token: rtmToken,
                showPopUpBeforeRemoteMute: false,
              }}
              callbacks={props.callbacks}
              styleProps={styles.styleProps}
            />
          </div>
        )}
      </Modal> */}

      <Modal
        visible={showUserModal}
        width="450"
        height="200"
        onClickAway={() => {
          setShowUserModal(false);
        }}
      >
        <div className="interested_users">
          {allInterestedUsers.length > 0 ? (
            <>
              <div className="users_image">
                <img
                  src={
                    process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH +
                    user.profile_pic
                  }
                />
              </div>
              <div className="interested_user_name">
                <h4> {user?.name} </h4>
              </div>
            </>
          ) : (
            <>
              <h4> No user Registered </h4>
            </>
          )}
        </div>
      </Modal>
      <Modal
        visible={showDeleteModal}
        width="450"
        height="200"
        onClickAway={() => {
          setDeleteModal(false);
        }}
      >
        <Row>
          <Col>
            <div className="justify-center mt-3">
              <h3
                classname="changeColor"
                style={{
                  color: "black",
                  marginLeft: "13px",
                  marginTop: "10px",
                }}
              >
                Are You sure you want to delete ?
              </h3>

              <Row>
                <Col>
                  <br />
                  <Button
                    className=""
                    color="secondary"
                    variant="contained"
                    style={{
                      margin: "10px",
                      backgroundColor: "#00504E",
                      color: "#fff",
                      width: "5rem",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      handleSessionDelete(sessionDetails?.id);
                    }}
                  >
                    <h5 style={{ margin: "auto" }}> Yes </h5>
                  </Button>
                  <Button
                    className=""
                    variant="contained"
                    color="primary"
                    style={{
                      margin: "10px",
                      marginLeft: "4px",
                      // backgroundColor: "#00504E",
                      border: "1px solid #00504E",
                      color: "#111",
                      width: "5rem",
                      textAlign: "center",
                    }}
                    onClick={() => setDeleteModal(false)}
                  >
                    <h5 style={{ margin: "auto" }}>No </h5>
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default CoachMyActivityLiveSessionComponent;
