
// import React, { useState, useEffect } from "react";
// import linkedIn from '../../../assets/images/icons/linkedin.png';
// import close from '../../../assets/images/icons/close.png';
// import view from '../../../assets/images/icons/view.png';
// import hidden from '../../../assets/images/icons/hidden.png';
// import { Button, Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
// import { NavLink, useNavigate } from "react-router-dom";
// import { userLogin, coachLogin, coachLinkedin } from "../../../utils/api";
// import { Formik } from "formik";
// import * as Yup from "yup";
// import SignUpComponenet from '../Signup';
// import ForgetModalComponenet from '../ForgetModal'
// import { toast } from 'react-toastify';
// import { useUserContext } from "../../../utils/hooks/UserContext";
// import { LinkedInApi } from '../../../utils/linkedinConfig'

// const validationSchema = Yup.object().shape({
//     email: Yup.string().email("Invalid email").required("Email is required"),
//     pwd: Yup.string().required('No password provided.')
//         .min(8, 'Password is too short - should be 8 chars minimum.')
//         .matches(/[a-zA-Z](?=.*[0-9])/, 'Password Should be alphanumeric'),
//     userType: Yup.string().required("User type is required"),
// });

// const validationSchemaLinkedIn = Yup.object().shape({
//     userType: Yup.string().required('Please select a user type'),
// });

// const validateData = (data) => {
//     const requiredFields = ["about", "background_img", "profile_pic"];

//     for (const field of requiredFields) {
//         if (!data?.[field] || (Array.isArray(data?.[field]) && data?.[field].length === 0)) {
//             return false;
//         }
//     }

//     return true;
// }

// const LoginComponent = (props) => {
//     const { getUserFromToken } = useUserContext();
//     const [isRevealPwd, setIsRevealPwd] = useState(false);
//     const [signUpModalShow, setSignUpModalShow] = React.useState(false);
//     const [isLoading, setisLoading] = useState(false)
//     const [forgetModalShow, setForgetModalShow] = React.useState(false);

//     const initialState = {
//         user: {},
//         loggedIn: false,
//     };

//     const [state, setState] = useState(initialState);

//     const getCodeFromWindowURL = (url) => {
//         const popupWindowURL = new URL(url);
//         return popupWindowURL.searchParams.get('code');
//     };

//     const handlePostMessage = async (event) => {
//         // console.log('1');
//         // console.log(event, '1111');
//         // console.log(event.data, '11111111111111');
//         // console.log('2222222222');
//         if (event.data.type === 'code') {
//             const { code } = event.data;
//             await getUserCredentials(code);
//         }
//     };

//     const getUserCredentials = async (code) => {
//         await coachLinkedin(code)
//             .then((res) => {
//                 const user = res.data;
//                 setState({
//                     user,
//                     loggedIn: true,
//                 });
//                 // Do something with user
//             });
//     };


//     const navigate = useNavigate()

//     const onSubmitHandler = async (values) => {
//         setisLoading(true)
//         try {
//             const formData = new FormData();
//             formData.append("emailid", values.email);
//             formData.append("password", values.pwd);

//             let res = null;

//             if (values.userType === 'user') {
//                 const { data } = await userLogin(formData);
//                 res = data
//             }
//             else {
//                 const { data } = await coachLogin(formData);
//                 res = data
//             }

//             if (res?.is_login) {
//                 toast.success('Signin successfully!')
//                 localStorage.setItem('token', res?.token)

//                 if (values.userType === 'coach') {
//                     if (validateData(res?.users)) {
//                         navigate('/coach/profile')
//                     } else {
//                         navigate('/coach')
//                     }
//                 }
//                 else {
//                     // if (values?.last_login) {
//                     //     navigate('/user')
//                     // }
//                     // else {
//                     //     navigate('/user/profile')
//                     // }
//                     window.location.assign(window.location.href)
//                 }

//             } else {
//                 toast.error(res?.message || 'Something went wrong');
//             }

//             getUserFromToken(res?.token)

//         } catch (error) {
//             toast.error(error?.response?.data?.message || error?.message)
//         } finally {
//             setisLoading(false)
//         }
//     };

//     const onClickForgetPassword = () => {
//         setForgetModalShow(true)
//         props.onHide()
//     }

//     const signUpWithLinkedin = () => {
//         console.log('1111111111');
//         const { clientId, redirectUrl, oauthUrl, scope, state } = LinkedInApi;
//         const oauthUrlWithParams = `${oauthUrl}&client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUrl}`;
//         const width = 450,
//             height = 730,
//             left = window.screen.width / 2 - width / 2,
//             top = window.screen.height / 2 - height / 2;
//         window.open(
//             oauthUrlWithParams,
//             'Linkedin',
//             'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
//             width +
//             ', height=' +
//             height +
//             ', top=' +
//             top +
//             ', left=' +
//             left
//         );
//     }

//     useEffect(() => {
//         if (window.opener && window.opener !== window) {
//             const code = getCodeFromWindowURL(window.location.href);
//             window.opener.postMessage({ type: 'code', code }, '*');
//             window.close();
//         }
//         window.addEventListener('message', handlePostMessage);

//         // return () => {
//         //     window.removeEventListener('message', handlePostMessage);
//         // };
//     }, []);

//     const handleNavLinkClick = () => {
//         setSignUpModalShow(true);
//         props.onHide();
//     };
    
//     const { loggedIn, user } = state;

//     return (
//         <>
//             <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
//                 <div className="brand_bg_color1 my_light_modal new_login_mdl">
//                     <Container>
//                         <Row className="justify-content-center">
//                             <Col sm="12">
//                                 <div className="ls_modal_conatiner">
//                                     <Button className="ls_mdl_close_btn p-0" onClick={props.onHide}>
//                                         <img alt="" src={close} />
//                                     </Button>
//                                     <div className=" text-start">
//                                         <h6 className="fs-15 fw-600 text_brand_color7">Join the Qoach Beta Program!</h6>
//                                         <p className="fs-12 fw-500">Exciting news! Qoach is now in beta, and we’re looking for users and coaches to help us shape the future of coaching and mentoring. This is your chance to get early access and be part of an exclusive group that gets to experience and influence our platform before anyone else.</p>
//                                     </div>
//                                     <Formik
//                                         initialValues={{ email: "", pwd: "", userType: "" }}
//                                         validationSchema={validationSchema}
//                                         onSubmit={onSubmitHandler}
//                                     >
//                                         {({ values, errors, touched, handleChange, handleSubmit }) => (
//                                             <Form onSubmit={handleSubmit}>
                                                

//                                                 {/* {
//                                                     values.userType === 'coach' ?
//                                                         <>
//                                                         </> */}
//                                                         {/* : */}
//                                                         <>
//                                                             <Form.Group className="mb-3" controlId="email">
//                                                                 <Form.Label>Name</Form.Label>
//                                                                 <Form.Control  style={{backgroundColor: '#ffffff !important'}} 
//                                                                     name="name"
//                                                                     type="text"
//                                                                     placeholder="Enter your name" 
//                                                                 />
//                                                             </Form.Group>

//                                                             <Form.Group className="mb-3" controlId="email">
//                                                                 <Form.Label>Email</Form.Label>
//                                                                 <Form.Control
//                                                                     name="email"
//                                                                     type="email"
//                                                                     placeholder="xyz@abe.com" 
//                                                                 />
//                                                             </Form.Group>
                                                            
//                                                             <Form.Group className="mb-3">
//                                                                 <Form.Label>Role</Form.Label>
//                                                                 <Row className="align-items-center g-2">
//                                                                     <Col className="col-auto">I am a</Col>
//                                                                     <Col>
//                                                                         {["radio"].map((type) => (
//                                                                             <Row key={`user_coach-${type}`} className="user_coach_rd_btn">
//                                                                                 <Col>
//                                                                                     <Form.Check
//                                                                                         label="User"
//                                                                                         name="userType"
//                                                                                         type={type}
//                                                                                         id={`user_coach-${type}-1`}
//                                                                                         value="user"
//                                                                                         onChange={handleChange}
//                                                                                         isInvalid={touched.userType && !!errors.userType}
//                                                                                     />
//                                                                                 </Col>
//                                                                                 <Col>
//                                                                                     <Form.Check
//                                                                                         label="Coach"
//                                                                                         name="userType"
//                                                                                         type={type}
//                                                                                         id={`user_coach-${type}-2`}
//                                                                                         value="coach"
//                                                                                         onChange={handleChange}
//                                                                                         isInvalid={touched.userType && !!errors.userType}
//                                                                                         defaultChecked={props.isCoach}
//                                                                                     />
//                                                                                 </Col>
//                                                                             </Row>
//                                                                         ))}
//                                                                     </Col>
//                                                                 </Row>
//                                                             </Form.Group>
//                                                             <Form.Group className="text-center mb-2">
//                                                                 <Button disabled={isLoading} variant="success" className="px-5" type="submit">
//                                                                     Submit
//                                                                 </Button>
//                                                             </Form.Group>
//                                                         </>
//                                                 {/* } */}
//                                             </Form>
//                                         )}
//                                     </Formik>


//                                 </div>
//                             </Col>
//                         </Row>
//                     </Container>
//                 </div>
//             </Modal>


//             <SignUpComponenet show={signUpModalShow} onHide={() => setSignUpModalShow(false)} />
//             <ForgetModalComponenet show={forgetModalShow} onHide={() => setForgetModalShow(false)} />

//         </>
//     )
// }

// export default LoginComponent;



import React, { useState, useEffect } from "react";
import linkedIn from "../../../assets/images/icons/linkedin.png";
import close from "../../../assets/images/icons/close.png";
import view from "../../../assets/images/icons/view.png";
import hidden from "../../../assets/images/icons/hidden.png";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import {
  userLogin,
  coachLogin,
  coachLinkedin,
  getLoggedInUser,
} from "../../../utils/api";
import { Formik } from "formik";
import * as Yup from "yup";
import SignUpComponenet from "../Signup";
import ForgetModalComponenet from "../ForgetModal";
import { toast } from "react-toastify";
import { useUserContext } from "../../../utils/hooks/UserContext";
import { LinkedInApi } from "../../../utils/linkedinConfig";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  pwd: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z](?=.*[0-9])/, "Password Should be alphanumeric"),
//   userType: Yup.string().required("User type is required"),
});

const validationSchemaLinkedIn = Yup.object().shape({
  userType: Yup.string().required("Please select a user type"),
});

const validateData = (data) => {
  const requiredFields = ["about", "background_img", "profile_pic"];

  for (const field of requiredFields) {
    if (
      !data?.[field] ||
      (Array.isArray(data?.[field]) && data?.[field].length === 0)
    ) {
      return false;
    }
  }

  return true;
};

const LoginComponent = (props) => {
  const { getUserFromToken } = useUserContext();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [signUpModalShow, setSignUpModalShow] = React.useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [forgetModalShow, setForgetModalShow] = React.useState(false);

  const initialState = {
    user: {},
    loggedIn: false,
  };

  const [state, setState] = useState(initialState);

  const getCodeFromWindowURL = (url) => {
    const popupWindowURL = new URL(url);
    return popupWindowURL.searchParams.get("code");
  };

  const handlePostMessage = async (event) => {
    // console.log('1');
    // console.log(event, '1111');
    // console.log(event.data, '11111111111111');
    // console.log('2222222222');
    if (event.data.type === "code") {
      const { code } = event.data;
      await getUserCredentials(code);
    }
  };

  const getUserCredentials = async (code) => {
    await coachLinkedin(code).then((res) => {
      const user = res.data;
      setState({
        user,
        loggedIn: true,
      });
      // Do something with user
    });
  };

  const navigate = useNavigate();

  const onSubmitHandler = async (values) => {
    setisLoading(true);
    try {
      const formData = new FormData();
      formData.append("emailid", values.email);
      formData.append("password", values.pwd);

      let res = null;

    //   if (getUser.data.data.userType === "user") {
    //     const { data } = await userLogin(formData);
    //     res = data;
    //   } else {
        const { data } = await coachLogin(formData);
        res = data;
      
      if (res?.is_login) {
        toast.success("Signin successfully!");
        localStorage.setItem("token", res?.token);
        const getUser = await getLoggedInUser({ token: res?.token })

        if (getUser.data.data.userType === "coach") {
          if (validateData(res?.users)) {
            navigate("/coach/profile");
          } else {
            navigate("/coach");
          }
        } else {
          // if (values?.last_login) {
          //     navigate('/user')
          // }
          // else {
          //     navigate('/user/profile')
          // }
          window.location.assign(window.location.href);
        }
      } else {
        toast.error(res?.message || "Something went wrong");
      }
      getUserFromToken(res?.token)
    } catch (error) {
      console.log(error);
      
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setisLoading(false);
    }
  };

  const onClickForgetPassword = () => {
    setForgetModalShow(true);
    props.onHide();
  };

  const signUpWithLinkedin = () => {
    // console.log('1111111111');
    const { clientId, redirectUrl, oauthUrl, scope, state } = LinkedInApi;
    const oauthUrlWithParams = `${oauthUrl}&client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUrl}`;
    const width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;
    window.open(
      oauthUrlWithParams,
      "Linkedin",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  useEffect(() => {
    if (window.opener && window.opener !== window) {
      const code = getCodeFromWindowURL(window.location.href);
      window.opener.postMessage({ type: "code", code }, "*");
      window.close();
    }
    window.addEventListener("message", handlePostMessage);

    // return () => {
    //     window.removeEventListener('message', handlePostMessage);
    // };
  }, []);

  const handleNavLinkClick = () => {
    setSignUpModalShow(true);
    props.onHide();
  };

  const { loggedIn, user } = state;

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="brand_bg_color1 my_light_modal">
          <Container>
            <Row className="justify-content-center">
              <Col sm="12">
                <div className="ls_modal_conatiner">
                  <Button
                    className="ls_mdl_close_btn p-0"
                    onClick={props.onHide}
                  >
                    <img alt="" src={close} />
                  </Button>
                  <div className="hdng_a text-start">
                    <h6 className="text-uppercase">LOGIN</h6>
                    <h2>Keep Your Progress Going</h2>
                  </div>
                  <p className="fs-16 fw-500">
                    Don’t have account?
                    {/* <NavLink className="text_brand_color2 fw-700" onClick={() => setSignUpModalShow(true), props.onHide} > */}
                    <NavLink
                      className="text_brand_color2 fw-700"
                      onClick={handleNavLinkClick}
                    >
                      <span className="ms-2">Sign up</span>
                    </NavLink>
                  </p>
                  <Formik
                    initialValues={{ email: "", pwd: "", userType: "" }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitHandler}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        {/* {["radio"].map((type) => (
                          <Row
                            key={`user_coach-${type}`}
                            className="user_coach_rd_btn"
                          >
                            <Col>
                              <Form.Check
                                label="User"
                                name="userType"
                                type={type}
                                id={`user_coach-${type}-1`}
                                value="user"
                                onChange={handleChange}
                                isInvalid={
                                  touched.userType && !!errors.userType
                                }
                              />
                            </Col>
                            <Col>
                              <Form.Check
                                label="Coach"
                                name="userType"
                                type={type}
                                id={`user_coach-${type}-2`}
                                value="coach"
                                onChange={handleChange}
                                isInvalid={
                                  touched.userType && !!errors.userType
                                }
                                defaultChecked={props.isCoach}
                              />
                            </Col>
                          </Row>
                        ))} */}

                        {/* {
                                                    values.userType === 'coach' ?
                                                        <>
                                                        </> */}
                        {/* : */}
                        <>
                          <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              name="email"
                              type="email"
                              placeholder="xyz@abe.com"
                              value={values.email}
                              onChange={handleChange}
                              isInvalid={touched.email && !!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="password">
                            <Form.Label>Password</Form.Label>
                            <InputGroup className="password_vh mb-3">
                              <Form.Control
                                placeholder="xyzabe@123"
                                name="pwd"
                                type={isRevealPwd ? "text" : "password"}
                                value={values.pwd}
                                onChange={handleChange}
                                isInvalid={touched.pwd && !!errors.pwd}
                                className="border-end-0"
                              />
                              <InputGroup.Text id="basic-addon2 border-start-0 bg-transparent">
                                <img
                                  alt=""
                                  width="20"
                                  title={
                                    isRevealPwd
                                      ? "Hide password"
                                      : "Show password"
                                  }
                                  src={isRevealPwd ? view : hidden}
                                  onClick={() =>
                                    setIsRevealPwd((prevState) => !prevState)
                                  }
                                />
                              </InputGroup.Text>
                              <Form.Control.Feedback type="invalid">
                                {errors.pwd}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>
                          <Form.Group className="text-end">
                            <NavLink
                              className="fs-16 fw-500 text_brand_color2"
                              onClick={onClickForgetPassword}
                            >
                              Forget Password?
                            </NavLink>
                          </Form.Group>
                          <Form.Group className="text-center mb-2">
                            <Button
                              disabled={isLoading}
                              variant="success"
                              className="w-100"
                              type="submit"
                            >
                              Login
                            </Button>
                          </Form.Group>
                          <Form.Group className="divider">Or</Form.Group>
                        </>
                        {/* } */}
                      </Form>
                    )}
                  </Formik>

                  <Formik
                    initialValues={{ userType: "" }}
                    validationSchema={validationSchemaLinkedIn}
                    onSubmit={signUpWithLinkedin}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <Form onSubmit={handleSubmit} className="mt-3">
                        {/* {["radio"].map((type) => (
                                                    <Row key={`user_coach-${type}`} className="user_coach_rd_btn">
                                                        <Col className="mb-3">
                                                            <Form.Check
                                                                label="User"
                                                                name="userType"
                                                                type={type}
                                                                id={`user_coach-${type}-1`}
                                                                value="user"
                                                                onChange={handleChange}
                                                                isInvalid={touched.userType && !!errors.userType}
                                                            />
                                                        </Col>
                                                        <Col className="mb-3">
                                                            <Form.Check
                                                                label="Coach"
                                                                name="userType"
                                                                type={type}
                                                                id={`user_coach-${type}-2`}
                                                                value="coach"
                                                                onChange={handleChange}
                                                                isInvalid={touched.userType && !!errors.userType}
                                                            />
                                                        </Col>
                                                    </Row>
                                                ))} */}
                        <Form.Group className="d-grid text-center">
                          <Button
                            variant="linkedin"
                            className="mx-auto"
                            type="submit"
                            onClick={signUpWithLinkedin}
                          >
                            <img
                              alt=""
                              className="me-2"
                              width="20"
                              src={linkedIn}
                            />{" "}
                            Login with LinkedIn
                          </Button>
                        </Form.Group>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Modal>

      <SignUpComponenet
        show={signUpModalShow}
        onHide={() => setSignUpModalShow(false)}
      />
      <ForgetModalComponenet
        show={forgetModalShow}
        onHide={() => setForgetModalShow(false)}
      />
    </>
  );
};

export default LoginComponent;
