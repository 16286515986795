import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "react-awesome-modal";
import {
  Accordion,
  Button,
  Col,
  Container,
  ListGroup,
  Row,
  Table,
} from "react-bootstrap";
import { useUserContext } from "../../../../utils/hooks/UserContext";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import quote_grn from "../../../../assets/images/icons/quote_grn.png";
import dummy_coach1_sqr from "../../../../assets/images/dummy/dummy_coach1_sqr.png";
import { toast } from "react-toastify";
import {
  createNewTransaction,
  registerUsersInProgram,
  sendMessage,
} from "../../../../utils/api";
import { PulseLoader } from "react-spinners";
import { loadStripe } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckout from "react-stripe-checkout";
import CheckoutForm from "./CheckoutForm";
import axios from "axios";

const mapPair = (pair) => {
  if (pair[1]) {
    return (
      <div className="item" key={pair?.[0]?.name + pair?.[1]?.name}>
        <div className="coach_details_testimonial_card pt-3">
          <div className="coach_details_testimonial_card_img">
            <img
              alt=""
              src={
                process.env.REACT_APP_COACH_TESTIMONIAL_PATH + pair?.[0]?.image
              }
            />
          </div>
          <div className="coach_details_testimonial_card_body">
            <div className="d-flex align-items-start">
              <img className="quote_grn" alt="" src={quote_grn} />
              <div className="ms-1">
                <p className="mb-2 fs-16 fw-500">{pair?.[0]?.description}</p>
                <h5 className="fs-20 fw-700 text_brand_color7">
                  {pair?.[0]?.company_name}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="coach_details_testimonial_card pt-3">
          <div className="coach_details_testimonial_card_img">
            <img
              alt=""
              src={
                process.env.REACT_APP_COACH_TESTIMONIAL_PATH + pair?.[1]?.image
              }
            />
          </div>
          <div className="coach_details_testimonial_card_body">
            <div className="d-flex align-items-start">
              <img className="quote_grn" alt="" src={quote_grn} />
              <div className="ms-1">
                <p className="mb-2 fs-16 fw-500">{pair?.[1]?.description}</p>
                <h5 className="fs-20 fw-700 text_brand_color7">
                  {pair?.[1]?.company_name}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="item" key={pair?.[0]?.name + pair?.[1]?.name}>
        <div className="coach_details_testimonial_card pt-3">
          <div className="coach_details_testimonial_card_img">
            <img
              alt=""
              src={
                process.env.REACT_APP_COACH_TESTIMONIAL_PATH + pair?.[0]?.image
              }
            />
          </div>
          <div className="coach_details_testimonial_card_body">
            <div className="d-flex align-items-start">
              <img className="quote_grn" alt="" src={quote_grn} />
              <div className="ms-1">
                <p className="mb-2 fs-16 fw-500">{pair?.[0]?.description}</p>
                <h5 className="fs-20 fw-700 text_brand_color7">
                  {pair?.[0]?.company_name}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const CoachDetailsAboutComponent = ({ userData }) => {
  const navigate = useNavigate();
  const publishableKey = process.env.Stripe_Publishable_Key;
  const { id } = useParams();
  const { user } = useUserContext();
  const [loading, setLoading] = useState(true);
  const [checkOut, setCheckout] = useState(false);
  const [stripe, setStripe] = useState(null);
  const [visible, setVisible] = useState(false);
  const [programDetails, setProgramDetails] = useState({});

  useEffect(() => {
    const canonicalUrl = window.location.origin + window.location.pathname;
    let link = document.querySelector("link[rel='canonical']");
    if (!link) {
      link = document.createElement("link");
      link.setAttribute("rel", "canonical");
      document.head.appendChild(link);
    }
    link.setAttribute("href", canonicalUrl);

    // console.log(userData);
    setLoading(false);
  }, []);

  const send_message = async () => {
    if (user.userType !== "user") {
      //   navigate("/");
      toast.error("Please login to do this action");
      return;
    }
    const data = {
      coach_id: userData?.coach_id,
      message: "Hello Sir",
    };
    const response = await sendMessage(data);
    if (response.data.status === true) {
      navigate("/user/messages");
    }
  };
  let stripePromise;

  const makePayment = async (id, coach_id, details) => {
    setProgramDetails(details);
    // console.log(id, coach_id);
    setVisible(true);
    // const data = await registerUsersInProgram({ coach_id, program_id: id });
    // if (data) {
    //   toast.success("You are registered successfully!");
    // } else {
    //   toast.error();
    // }
  };

  async function onCompletePayment(e, address) {
    // console.log(e, address);
    try {
      // alert("Payment complated!!!")

      const data = await registerUsersInProgram({
        coach_id: userData.coach_id,
        program_id: programDetails.id,
      });
      if (data) {
        toast.success("You are registered successfully!");
      } else {
        toast.error();
      }
      const payload = {
        coach_id: userData.coach_id,
        stripe_transaction_id: e.id,
        total_payment: parseInt(programDetails.price),
        session_id: programDetails.id,
      };
      navigate("/user/my-programs")
      // createNewTransaction(payload);
      // addInterestforSession(sessionDetails.id, sessionDetails.coach_id);
    } catch (error) {
      console.log(error);
    }
  }

  const pairs = [];
  for (let i = 0; i < userData?.testimonials?.length; i += 2) {
    pairs.push([userData?.testimonials?.[i], userData?.testimonials?.[i + 1]]);
  }

  return (
    <>
      {loading ? (
        // <PulseLoader color="#36D7B7" loading={loading} size={15} />
        <div
          style={{
            // textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <p>
            <PulseLoader color="#00504E" size={20} />
          </p>
        </div>
      ) : (
        <>
          <Container className="coach_details_about">
            <Row>
              <Col lg="9">
                <div className="brand_bg_color1 card p-4">
                  <Row>
                    {/* <Col className="col-auto">
                  {userData?.profile_pic && (
                    <img
                      className="coach_details_img"
                      src={`${process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH}${userData?.profile_pic}`}
                      alt=""
                    />
                  )}
                  {!userData?.profile_pic && (
                    <img
                      className="coach_details_img"
                      src={dummy_coach1_sqr}
                      alt=""
                    />
                  )}{" "}
                </Col> */}
                    <Col>
                      <div>
                        <div className="text-left hdng_a">
                          <h6 className="fs-16 text-uppercase">ABOUT</h6>
                          <h2 className="fs-24 m-0">My Introduction</h2>
                        </div>
                        <p
                          className="fs-14 fw-400"
                          dangerouslySetInnerHTML={{ __html: userData?.about }}
                        />
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="mt-3">
                        <div className="text-left hdng_a">
                          <h6 className="fs-16 text-uppercase">MY STORY</h6>
                          <h2 className="fs-24 m-0">Reflections on My Life</h2>
                        </div>
                        <p
                          className="fs-14 fw-400"
                          dangerouslySetInnerHTML={{ __html: userData?.story }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg="3">
                <div className="edu_we_certi_tags_card">
                  <ListGroup>
                    <ListGroup.Item>
                      <h5 className="fs-20 fw-600 text_brand_color7 m-0">
                        Education
                      </h5>
                      {userData?.educations?.map((i) => {
                        return (
                          <div>
                            <div className="mt-2">
                              <p className="fs-14 fw-500 m-0">{i?.course}</p>
                              <div className="text-end m-0">
                                <p className="fs-12 m-0 opacity_7">
                                  {i?.university_colleage}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <h5 className="fs-20 fw-600 text_brand_color7 m-0">
                        Work Experience
                      </h5>
                      {userData?.experiences?.map((i) => {
                        return (
                          <div className="mt-2">
                            <p className="fs-14 fw-500 m-0">{i?.title}</p>
                            <div className="text-end m-0">
                              <p className="fs-12 m-0 opacity_7">
                                {i?.company_name}
                              </p>
                              <p className="fs-12 m-0 opacity_7">
                                {i?.start_date} - {i?.end_date}
                              </p>
                              <p className="fs-12 m-0 opacity_7">
                                {i?.workexperience}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <h5 className="fs-20 fw-600 text_brand_color7 m-0">
                        Certificates
                      </h5>
                      {userData?.certificates?.map((i) => {
                        return (
                          <div className="mt-2">
                            <p className="fs-14 fw-500 m-0">{i?.name}</p>
                            <div className="text-end m-0">
                              <p className="fs-12 m-0 opacity_7">
                                {i?.organization}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <h5 className="fs-20 fw-600 text_brand_color7 m-0">
                        Tags
                      </h5>

                      {userData?.tags?.map((i) => {
                        return (
                          <div className="px-1 d-inline-flex fs-14">#{i}</div>
                        );
                      })}
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="py-4 coach-personal-details">
            <Row>
              <Col>
                <div className="text-left hdng_a">
                  <h6 className="fs-16 text-uppercase">Skills</h6>
                  <h2 className="fs-24 m-0">Area of my Expertise </h2>
                </div>
                <div className="mt-3">
                  {userData?.skills?.map((i) => {
                    return <Button variant="added_language">{i?.name}</Button>;
                  })}
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="py-4 coach-personal-details">
            <Row>
              <Col>
                <div className="text-left hdng_a">
                  <h6 className="fs-16 text-uppercase">specialties</h6>
                  <h2 className="fs-24 m-0">What you can expect </h2>
                </div>
                <div className="plus_link_accordion mt-3">
                  <Accordion
                    className="pls_link_accordian"
                    defaultActiveKey="0"
                  >
                    {userData?.specialties?.map((i, index) => {
                      return (
                        <Accordion.Item eventKey={i}>
                          <Accordion.Header>
                            <span>{index + 1}.</span> {i?.speciality}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <p className="m-0">{i?.description}</p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>

          <section className="brand_bg_color1 py-5">
            <Container>
              <Row className="align-items-center">
                <Col>
                  <div className="text-left hdng_a">
                    <h6 className="fs-16 text-uppercase">Feees</h6>
                    <h2 className="fs-24 m-0">What you will pay</h2>
                  </div>
                </Col>
              </Row>

              {userData?.programs?.map((session) => (
                <Row className="mt-4 align-items-center">
                  <Col>
                    {/* <p className="fs-14 fw-500 m-0 brand_text_color7">#EI1254</p> */}
                    <h5 className="fs-18 fw-500 text_brand_color7">
                      {" "}
                      {session.session_title}{" "}
                    </h5>
                  </Col>

                  <Col className="col-auto">
                    <span className="fs-20 fw-500 text_brand_color2 me-4">
                      {session.duration} Days
                    </span>
                    <span className="fs-20 fw-500 text_brand_color2 me-4">
                      $ {session.price}
                    </span>
                    <Button
                      className=""
                      size="sm"
                      variant="rounded_success"
                      onClick={(e) => {
                        // console.log(user);
                        if (!user) {
                          toast.error("Please login as a user!");
                          return;
                        }
                        if (user.userType === "coach") {
                          return toast.error(
                            "Please login as a user in the program!"
                          );
                        }
                        if (user.userType === "user") {
                          makePayment(session?.id, userData?.coach_id, session);
                        }
                      }}
                    >
                      Book Now
                    </Button>
                  </Col>

                  <Col sm="12">
                    <p className="fs-14 fw-500 m-0 brand_text_color7">
                      Define technical project scope and receive proposals
                      .Launch new software, SAAS, website, or mobile app.Receive
                      project estimation and technology selection. Benefit from
                      competitor analysis and UI/UX design consultation.
                    </p>
                  </Col>
                  {session?.sessions !== null && (
                    <Col sm="12" className="simple_Accordion">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            {session?.sessions?.length} Sessions
                          </Accordion.Header>
                          {session?.sessions?.map((item, index) => (
                            <>
                              <Accordion.Body>
                                <Table>
                                  <tr>
                                    {/* <td className="fs-12 fw-500">#BEI25421</td> */}
                                    <td className="fs-14 fw-500 text_brand_color7">
                                      {item.name}
                                    </td>
                                    <td className="text-end fs-14 fw-500">
                                      {item.duration} minutes
                                    </td>
                                  </tr>
                                </Table>
                              </Accordion.Body>
                            </>
                          ))}
                        </Accordion.Item>
                      </Accordion>
                    </Col>
                  )}
                </Row>
              ))}

              {/* 
                <Row className="mt-4 align-items-center">
                  <Col>
                    <p className="fs-14 fw-500 m-0 brand_text_color7">#EICC2542</p>
                    <h5 className="fs-18 fw-500 text_brand_color7">Emotional Intelligence complete course</h5>
                  </Col>
                  <Col className="col-auto">
                    <span className="fs-20 fw-500 text_brand_color2 me-4">
                      15 Days
                    </span>
                    <span className="fs-20 fw-500 text_brand_color2 me-4">
                      $ 1199
                    </span>
                    <Button className="" size="sm" variant="rounded_success">Book Now</Button>
                  </Col>
                  <Col sm="12">
                    <p className="fs-14 fw-500 m-0 brand_text_color7">Define technical project scope and receive proposals .Launch new software, SAAS, website, or mobile app.Receive project estimation and technology selection. Benefit from competitor analysis and UI/UX design consultation.</p>
                  </Col>
                  <Col sm="12" className="simple_Accordion">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>6 Sessions</Accordion.Header>
                        <Accordion.Body>
                          <Table>
                            <tr>
                              <td className="fs-12 fw-500">#BEI25421</td>
                              <td className="fs-14 fw-500 text_brand_color7">Basic Emotional Intelligence</td>
                              <td className="text-end fs-14 fw-500">30 minutes</td>
                            </tr>
                            <tr>
                              <td className="fs-12 fw-500">#BEI25421</td>
                              <td className="fs-14 fw-500 text_brand_color7">Basic Emotional Intelligence</td>
                              <td className="text-end fs-14 fw-500">30 minutes</td>
                            </tr>
                            <tr>
                              <td className="fs-12 fw-500">#BEI25421</td>
                              <td className="fs-14 fw-500 text_brand_color7">Basic Emotional Intelligence</td>
                              <td className="text-end fs-14 fw-500">30 minutes</td>
                            </tr>
                            <tr>
                              <td className="fs-12 fw-500">#BEI25421</td>
                              <td className="fs-14 fw-500 text_brand_color7">Basic Emotional Intelligence</td>
                              <td className="text-end fs-14 fw-500">30 minutes</td>
                            </tr>
                            <tr>
                              <td className="fs-12 fw-500">#BEI25421</td>
                              <td className="fs-14 fw-500 text_brand_color7">Basic Emotional Intelligence</td>
                              <td className="text-end fs-14 fw-500">30 minutes</td>
                            </tr>
                            <tr>
                              <td className="fs-12 fw-500">#BEI25421</td>
                              <td className="fs-14 fw-500 text_brand_color7">Basic Emotional Intelligence</td>
                              <td className="text-end fs-14 fw-500">30 minutes</td>
                            </tr>
                            <tr>
                              <td className="fs-12 fw-500">#BEI25421</td>
                              <td className="fs-14 fw-500 text_brand_color7">Basic Emotional Intelligence</td>
                              <td className="text-end fs-14 fw-500">30 minutes</td>
                            </tr>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                  
                </Row> */}
            </Container>
          </section>

          <Container className="py-4 coach-personal-details">
            <Row>
              <Col md="6">
                <div className="text-left hdng_a">
                  <h6 className="fs-16 text-uppercase">BENIFITS</h6>
                  <h2 className="fs-24 m-0">Why choose me </h2>
                </div>
                {userData?.benefits?.map((i) => {
                  return (
                    <ul className="dt_arrow_list">
                      <li>{i}</li>
                    </ul>
                  );
                })}
              </Col>
              <Col md="6">
                <div className="position-relative ps-4">
                  <div className="text-left hdng_a">
                    <h6 className="fs-16 text-uppercase">MY CLIENTS</h6>
                    <h2 className="fs-24 m-0">Whom I can help</h2>
                  </div>
                  {userData?.professions?.map((i) => {
                    return (
                      <ul className="dt_arrow_list">
                        <li>{i}</li>
                      </ul>
                    );
                  })}
                  <hr className="vertical" />
                </div>
              </Col>
            </Row>
          </Container>

          {/* <section className="brand_bg_color1 py-5">
            <Container>
              <Row className="align-items-center">
                <Col>
                  <div className="text-left hdng_a">
                    <h6 className="fs-16 text-uppercase">Pricing</h6>
                    <h2 className="fs-24 m-0">What you will pay</h2>
                  </div>
                </Col>
                <Col className="col-auto">
                  <Button variant="success">Free Session</Button>
                </Col>
              </Row>
              {userData?.programs?.map((i) => {
                return (
                  <>
                    <Row className="mt-4 align-items-center">
                      <Col>
                        <h5 className="fs-18 fw-500 text_brand_color7">
                          {i?.session_title}
                        </h5>
                        <p className="fs-14 fw-500 m-0 brand_text_color7">
                          {i?.no_of_session} Live meeting on online platform for{" "}
                          {i?.duration} days
                        </p>
                      </Col>
                      <Col className="col-auto">
                        <h4 className="fs-30 fw-500 text_brand_color2 m-0">
                          ${i?.price}
                        </h4>
                      </Col>
                    </Row>
                    <hr />
                  </>
                );
              })}
            </Container>
          </section> */}

          {/* <Container className="coach_details_testimonial white_bg py-5">
            <Row>
              <Col>
                <div className="text-left hdng_a">
                  <h6 className="fs-16 text-uppercase">Testimonial</h6>
                  <h2 className="fs-24 m-0">What my client say</h2>
                </div>
              </Col>
            </Row>

            <OwlCarousel
              className="owl-theme"
              autoplay
              loop
              margin={10}
              items="3"
              dots
              responsive={{
                1: {
                  items: 1,
                },
                800: {
                  items: 1,
                },
                1025: {
                  items: 1,
                },
              }}
            >
              {pairs.map(mapPair)}
            </OwlCarousel>
          </Container> */}
        </>
      )}
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        visible={visible}
        onClickAway={() => setVisible(false)}
        // height="500"
        className="shortvideo-model h-90 my_light_modal default-popup"
      >
        <div className="brand_bg_color1 my_light_modal">
          <div className="ls_modal_conatiner p-4">
            <div className="hdng_a text-start">
              <h6 className="text-uppercase"> </h6>
              <h4> You are registering for </h4>
              <h3 className="my-3 fw-700" style={{ fontFamily: "volkhov" }}>
                {programDetails.session_title}
              </h3>
              <h5> by {userData.name} </h5>
            </div>
            {/* <form clasName="px-lg-4"> */}
            <div className="mb-3 mt-3">
              <h6>Session Price: {programDetails.price} </h6>
              {/* <div className="invalid-feedback">Password is required</div> */}
            </div>
            <div>
              <StripeCheckout
                label="Pay Now"
                name="Qoach International Private Limited"
                billingAddress
                shippingAddress
                description={`Your Total ₹${programDetails.price}`}
                amount={programDetails.price * 100}
                panelLabel="Pay Now"
                token={onCompletePayment}
                stripeKey={
                  "pk_test_51PeB04RxzGBCno7VRNimXve86fQloq2C3ev3FNoV7om4R64hYqxgoAt7S9VdjBiqV6yCrAgSVa4tdgwkFBIkLbHK00VPS3Noig"
                }
              />
            </div>
            {/* </form> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CoachDetailsAboutComponent;
