
import React, { useEffect, useState, useContext, useRef } from "react";
// import { NavLink } from "react-router-dom";
// import { Button, Col, Row } from "react-bootstrap";
import Modal from "react-awesome-modal";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import testimonials_img1 from "../../../assets/images/dummy/testimonials_img1.png";
// import live_session_img1 from "../../../assets/images/dummy/live_session_img1.png";
// import DatePicker from "react-datepicker";
// import Picker from "emoji-picker-react";
import EmojiPicker from 'emoji-picker-react';

import "react-datepicker/dist/react-datepicker.css";
import like from "../../../assets/images/like.png";
import AgoraRTC, { ILocalVideoTrack } from "agora-rtc-sdk-ng";
import AgoraUIKit, { layout } from "agora-react-uikit";
import { initializeApp, getApps, getApp } from "firebase/app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faXmark,
    faSearch,
    faFilter,
    faLocationDot,
    faUserPlus,
    faPaperPlane,
    faWifi,
    faThumbsUp,
    faShareNodes,
    faArrowUpFromBracket,
    faUpRightFromSquare,
    faRectangleXmark,
    faVideo,
    faRecordVinyl,
    faPlay,
    faSquare,
    faDownload,
    faGlobe,
    faEllipsis,
    faChartLine,
    faBan,
    faPencil,
} from "@fortawesome/free-solid-svg-icons";
import {
    faClock,
    faCalendarDays,
    faPenToSquare,
    faComments,
    faCirclePlay,
    faFaceSmileBeam,
} from "@fortawesome/free-regular-svg-icons";
import {
    getDatabase,
    ref,
    set,
    push,
    get,
    remove,
    child,
    onValue,
    update,
} from "firebase/database";

// import {
//       createSession,
//       coachSessionList,
//       editSession,
//       getMyFollowings,
//     myContacts,
// } from "../../../utils/api";
// import EditIcon from "../../../assets/images/EditIcon.png";
import {
    //   UserContextProvider,
    useUserContext,
} from "../../../utils/hooks/UserContext";
// import { toast } from "react-toastify";
import axios from "axios";
// import { MultiSelect } from "react-multi-select-component";
import { PulseLoader } from "react-spinners";
import { changeSessionStatus } from "../../../utils/api";

const firebaseConfig = {
    apiKey: "AIzaSyD3z4lfq5pifockofAFWcBQC5vHxOnlVRw",
    authDomain: "qoach-f7246.firebaseapp.com",
    databaseURL: "https://qoach-f7246-default-rtdb.firebaseio.com",
    projectId: "qoach-f7246",
    storageBucket: "qoach-f7246.appspot.com",
    messagingSenderId: "337868227214",
    appId: "1:337868227214:web:55bdbe4bf73b3808c23fdf",
    measurementId: "G-DV4W9KHXYV"
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
let client = AgoraRTC.createClient({
    mode: "rtc",
    codec: "vp8",
});
function LiveStreamComponent({ videoCallSessionId, videoCallSessionName, sessionDetails }) {
    const { user } = useUserContext();
    const emojiRef = useRef(null);
    
    const [agoraToken, setAgoratoken] = useState("");
    const [videoCall, setVideocall] = useState(true);
    const [rtmToken, setRtmToken] = useState("");
    const [chatOpen, setChatOpen] = useState(false);
    const [allComments, setAllComments] = useState([]);
    const [videoLayout, setVideoLayout] = useState(layout.grid);
    const [hideCommentBox, setHideCommentBox] = useState(false);
    const [channelName, setChanelName] = useState("");
    const [message, setMessage] = useState("");
    const [emojiToggle, setEmojiToggle] = useState(false);
    const [shareScreen, setShareScreen] = useState(false);
    const [screenVideoTrack, setScreenVideoTrack] = useState(null);
    const [remoteUsers, setRemoteUsers] = useState({});
    const [localId, setLocalId] = useState(0);
    const [joined, setJoined] = useState(false);
    // const videoRef = useRef();

    const app_id = "890c456575c247c7aab98108ca312bad";

    
    useEffect(() => {
        // console.log(sessionDetails, "--------------------- KITE");
        if(sessionDetails.session_type === "free_session" ){
            const timerDuration = 20 * 60 * 1000; // 20 minutes in milliseconds
            const intervalDuration = 1000; // 1 second in milliseconds
            let timer = 0;
            const interval = setInterval(() => {
                timer += intervalDuration;
                // console.log(timer);
                console.log(timer)
                if (timer >= timerDuration) {
                    clearInterval(interval); // Clear the interval after 20 minutes
                    completeSession();
                    setVideocall(false)
                }
            }, 1000);
        }
        // window.scrollTo(0, 0);
        getData();
        // const now = new Date();
        // setCurrentTime(formatTime(now));
        // getMyFollowers();      
    }, []);
    const share_screen_firebase = async (type, msg_type) => {
        let chnlName = `${videoCallSessionName.replace(/ /g, "_")}_` + videoCallSessionId;
        chnlName = chnlName.replace(/'/g, "_");
        chnlName = sanitizeChannelName(chnlName);

        const db = getDatabase();
        const commentRef = ref(db, "Share_Screen/" + chnlName);

        push(commentRef, {
            au_id: user.coach_id ? user.coach_id : user?.user_id,
            au_image: user?.profile_pic,
            au_message:
                type === "heart"
                    ? ""
                    : type === "share_screen"
                        ? `${user.name} is sharing the screen!`
                        : message,
            au_name: user?.name,
            channelName: chnlName,
            screenShareUid: 9999,
            msg_type: msg_type,
        });
        setMessage("");
        fetchScreenShare(chnlName);
    };
    const sanitizeChannelName = (name) => {
        // Replace unsupported characters and limit length to 64 bytes
        let sanitized = name.replace(/[^a-zA-Z0-9 !#$%&()*+,-:;<=.>?@[\\\]^_{|}~]/g, '');
        if (sanitized.length > 64) {
            sanitized = sanitized.substring(0, 64);
        }
        return sanitized;
    };
    const getData = async () => {
        let chnlName = `${videoCallSessionName.replace(/ /g, "_")}_` + videoCallSessionId;
        chnlName = chnlName.replace(/'/g, "_");
        chnlName = sanitizeChannelName(chnlName);
        setChanelName(chnlName);
        // console.log(user, 'userrr');
        // alert('1111111111');
        let userid = user.coach_id ? `${user.coach_id}` : `${user?.user_id}`
        generateToken(chnlName, userid);
        checkColorAndPosition(user?.user_id);
        fetchComments(chnlName);
        fetchScreenShare(chnlName)
    };


    const styles = {
        container: {
            width: "100%",
            height: "50%",
            display: "grid",
            backgroundColor: "#111",
            objectFit: "contain"
        },
        heading: { textAlign: "center", marginBottom: 0 },
        videoContainer: {
            display: "grid",
            flexDirection: "column",
            backgroundColor: "#ffffff",
        },
        nav: { display: "block", justifyContent: "space-around" },
        btn: {
            backgroundColor: "#007bff",
            cursor: "pointer",
            borderRadius: 5,
            padding: 5,
            color: "#ffffff",
            fontSize: 20,
        },
        styleProps: {
            height: "50vh",
            width: "50vw",
            backgroundColor: "#FFF",
            maxViewStyles: {
                marginTop: videoLayout === layout.pin ? "3rem" : "0",
                width: videoLayout === layout.grid ? "100%" : "80%",
                //   height: videoLayout === layout.grid ? "100vh" : "90vh",
                //   objectFit: "contain",
                //   backgroundColor: "#111",
                //   padding: "5px"
            },
            // minViewStyles:{
            // //  marginTop: "3rem"
            //   // height: "20vh",
            //   // width: "20vh"
            // }
        },
    };

    const updateSession = async () => {
        const payload = {
          session_status: "completed",
          id: sessionDetails.id
        };
        await changeSessionStatus(payload);
      };
    

    const completeSession = async () => {
        setVideocall(false);
        updateSession()
        window.location.reload()
    };
    let props = {
        rtcProps: {
            appId: app_id,
            channel: "",
            token: "",
        },
        callbacks: {
            EndCall: completeSession,
        },
    };

    

    const generateTokenn = async (chnlName, userid) => {
        // console.log(chnlName, 'chnlName');
        // console.log(userid, 'userid');
        try {
            const data = await axios.post(
                "https://api.qoach.io/api/v1/agora/token",
                {
                    channelName: chnlName,
                    user_id: userid,
                }
            );
            if (data) {
                // console.log(data, 'agora token');
                return (data)
                // setVideocall(true);
                // setAgoratoken(data.data.data);
                // setRtmToken(data.data.rtm_token);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const generateToken = async (chnlName, userid) => {
        // console.log(chnlName, 'chnlName');
        // console.log(userid, 'userid');
        try {
            const data = await axios.post(
                "https://api.qoach.io/api/v1/agora/token",
                {
                    channelName: chnlName,
                    user_id: userid,
                }
            );
            if (data) {
                // console.log(data, 'agora token');
                setVideocall(true);
                setAgoratoken(data.data.data);
                setRtmToken(data.data.rtm_token);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const whiteBoardStart = async () => {
        // Create an Interactive Whiteboard room from your app server
        const postData = {
            isRecord: false
        };
        const config = {
            headers: {
                token: "NETLESSSDK_YWs9VW5xTndPZEtpWTA3ak9oVyZub25jZT01ZjRlOGY3MC1lY2Q0LTExZWUtYTFkMC01NzdjY2MyZjE2YTUmcm9sZT0wJnNpZz1lMTc1ZDNkZWNiYjlmM2FhZmU0N2VkNmE5MDNmNzM2OTVkMWJhY2E1NGJlMTZmMDdhMTBlZWMyN2EwMjYyYzkz",
                region: "us-sv",
                contentType: "application/json"
            }
        };

        const createServer = await axios.post("https://api.netless.link/v5/rooms", postData, config)
        // .then(response => {
        //   console.log(response.data); // assuming the response data is what you're interested in
        // })
        // .catch(error => {
        //   console.error(error);
        // });

        // Generate a room token
        // console.log(createServer, "create Server");
        if (createServer) {
            const tokenData = {
                lifespan: 3600000,
                role: "admin"
            };
            await axios.post(`https://api.netless.link/v5/tokens/rooms/${createServer.data.uuid}`, tokenData, config).then(async (resp) => {
                // console.log(resp.data)
                //   let app
                //   app = await createFastboard({
                //     sdkConfig: {
                //         appIdentifier: "1YpBQOUMEe6y5n2xefKntg/F-jFZYWlVWCSJQ",
                //         region: "cn-hz",
                //     },
                //     joinRoom: {
                //         uid: user?.id,
                //         uuid: createServer.data.uuid,
                //         roomToken: resp.data,
                //     },
                //     managerConfig: {
                //         cursor: true,
                //     },
                // });
                // window.app = app;
                // return mount(app, "div");
                // var whiteWebSdk = new WhiteWebSdk({
                //   // Pass in your App Identifier.
                //   appIdentifier: "1YpBQOUMEe6y5n2xefKntg/F-jFZYWlVWCSJQ",
                //   // Set the data center as Silicon Valley, US.
                //   region: "us-sv",
                // })

                // var joinRoomParams = {
                //   uuid: createServer.uuid,
                //   // The unique identifier of a user. If you use versions earlier than v2.15.0, do not add this line.
                //   uid: user?.id,
                //   roomToken: resp.data,
                // };

                // // Join the whiteboard room and display the whiteboard on the web page.
                // whiteWebSdk.joinRoom(joinRoomParams).then(function(room) {
                //     room.bindHtmlElement(document.getElementById("whiteboard"));
                // }).catch(function(err) {
                //     console.error(err);
                // });

            })
                .catch(err => {
                    console.log(err);
                })
        }

    }
    const onEmojiClick = (event, emojiObject) => {
        setMessage((prevInput) => prevInput + event.emoji);
    };
    const fetchScreenShare = (chnlName) => {
        const dbRef = ref(getDatabase());
        const commentsRef = child(dbRef, `Share_Screen/${chnlName}`);
        onValue(
            commentsRef,
            (snapshot) => {
                if (snapshot.exists()) {
                    let comments = [];
                    const data = snapshot.val();
                    const mappedObjects = Object.keys(data).map((key) => {
                        comments.push(data[key]);
                    });

                    let filter_scrn_shre = comments[comments.length - 1];
                    // console.log(filter_scrn_shre, 'filter_scrn_shre');
                    if (filter_scrn_shre.msg_type === "share") {
                        setVideoLayout(layout.pin);

                    } else {
                        setVideoLayout(layout.grid);
                    }
                } else {
                    // setAllComments(); // Assuming you want to clear the comments if no data exists
                }
            },
            (error) => {
                console.error(error);
            }
        );
    };

    const fetchComments = (chnlName) => {
        const dbRef = ref(getDatabase());

        const commentsRef = child(dbRef, `BroadComments/${chnlName}`);

        onValue(
            commentsRef,
            (snapshot) => {
                if (snapshot.exists()) {
                    let comments = [];
                    const data = snapshot.val();
                    const mappedObjects = Object.keys(data).map((key) => {
                        comments.push(data[key]);
                    });
                    // console.log(comments.reverse());
                    setAllComments(comments.reverse());
                } else {
                    // setAllComments(); // Assuming you want to clear the comments if no data exists
                }
            },
            (error) => {
                console.error(error);
            }
        );
    };

    const sendComment = (e) => {
        setMessage(e.target.value);
    };
    const handleUserPublished = async (user, mediaType) => {
        const id = user.coach_id ? user.coach_id : user?.user_id;
        await subscribe(user.coach_id ? user.coach_id : user?.user_id, mediaType);
        setRemoteUsers((prev) => ({
            ...prev,
            [id]: user.coach_id ? user.coach_id : user?.user_id,
        }));
    };
    const subscribe = async (user, mediaType) => {
        await client.subscribe(user, mediaType);
    };
    const handleUserUnpublished = (user, mediaType) => {
        if (mediaType === "video") {
            const id = user.coach_id ? user.coach_id : user?.user_id;
            setRemoteUsers((pre) => {
                delete pre[id];
                return {
                    ...pre,
                };
            });
        }
    };
    const share_screen = async () => {
        // const formData = new FormData();
        // document.documentElement.style.zoom = "0.5";
        // var localUserId = Math.floor(Math.random() * 1000000).toString();
        var localUserId = "9999"
        setLocalId(localUserId)
        // formData.append("channelName", channelName);
        // formData.append("user_id", localUserId);
        let chnlName = `${videoCallSessionName.replace(/ /g, "_")}_` + videoCallSessionId;
        chnlName = chnlName.replace(/'/g, "_");
        chnlName = sanitizeChannelName(chnlName);


        const agoraTkn = await generateTokenn(chnlName, localUserId);
        // console.log(agoraTkn, 'agoraTkn token');
        const tracks = await AgoraRTC.createScreenVideoTrack(
            {
                encoderConfig: "720p",
            },
            "auto"
        );
        setScreenVideoTrack(tracks[0]);
        // console.log(chnlName, 'chnlName');
        // console.log(agoraTkn, 'agoraTkn');
        await client.join(
            "ea5af47240ea4b988de2598bda70708d",
            chnlName,
            agoraTkn?.data?.data,
            localUserId
        );
        client.on("user-published", handleUserPublished);
        client.on("user-unpublished", handleUserUnpublished);
        await client.publish(tracks);
        setJoined(true);

        var mainVideoContainer = document.getElementById("mainVideoContainer");

        client.on("stream-added", function (evt) {
            var remoteStream = evt.stream;
            client.subscribe(remoteStream, function (err) {
                console.error("Error subscribing to remote stream", err);
            });
        });

        client.on("stream-subscribed", function (evt) {
            var remoteStream = evt.stream;
            displaySharedScreen(remoteStream);
        });

        function displaySharedScreen(stream) {
            var sharedScreenVideoElement = document.createElement("video");
            sharedScreenVideoElement.srcObject = stream.stream;
            sharedScreenVideoElement.autoplay = true;

            mainVideoContainer.innerHTML = "";
            mainVideoContainer.appendChild(sharedScreenVideoElement);
        }

        setVideoLayout(layout.pin);
        share_screen_firebase("share_screen", "share");
        setShareScreen(true);
        // console.log(max, "maximun");

        const targetDiv = document.getElementById("screen-share");
        if (targetDiv) {
            targetDiv.onclick = stopScreenShare;
            targetDiv.innerHTML =
                '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="rectangle-xmark" class="svg-inline--fa fa-rectangle-xmark " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"></path></svg>';
        }
    };
    const stopScreenShare = async () => {
        screenVideoTrack?.close();
        setScreenVideoTrack(null);
        setRemoteUsers({});
        await client?.leave();
        setJoined(false);
        setShareScreen(false);
        // document.documentElement.style.zoom = "1";
        const msg = "client leaves channel success!";
        share_screen_firebase("share_screen", "hide");
        const targetDiv = document.getElementById("screen-share");
        if (targetDiv) {
            targetDiv.onclick = share_screen;
            targetDiv.innerHTML =
                '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="up-right-from-square" class="svg-inline--fa fa-up-right-from-square " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32c0-17.7-14.3-32-32-32H352zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"></path></svg>';
        }
    };
    const checkColorAndPosition = (userId) => {
        let timer = setInterval(() => {
            const targetDiv = document.querySelector(
                ".video-call-container-close-chat > div > div:nth-child(2)",
                ".video-call-container > div > div:nth-child(2)",
            );
            // console.log(targetDiv);
            if (targetDiv) {
                const computedStyle = window.getComputedStyle(targetDiv);
                const backgroundColor = computedStyle.backgroundColor;
                if (
                    backgroundColor.includes("blue") ||
                    backgroundColor.includes("rgb(0, 123, 255)") ||
                    backgroundColor.includes("rgba(0, 0, 0, 0)")
                ) {
                    targetDiv.style.backgroundColor = "rgba(0, 0, 0, 0.65)";
                    targetDiv.style.position = "absolute";
                    // targetDiv.style.width = "35em";
                    // targetDiv.style.minWidth = "7em";

                    targetDiv.style.bottom = "1em";
                    targetDiv.style.left = "50%";
                    targetDiv.style.transform = "translateX(-50%)";
                    targetDiv.style.borderRadius = "5em";

                    createButton(
                        "white-board",
                        // "/event-whiteBoard/" + eventDetails?.id,
                        whiteBoardStart,
                        '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="pen-to-square" class="svg-inline--fa fa-pen-to-square " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"></path></svg>',
                        "White Board",
                        userId
                    );
                    chatOpen === true
                        ? createButton(
                            "comments",
                            chatSectionToggle,
                            '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="comments" class="svg-inline--fa fa-comments " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M88.2 309.1c9.8-18.3 6.8-40.8-7.5-55.8C59.4 230.9 48 204 48 176c0-63.5 63.8-128 160-128s160 64.5 160 128s-63.8 128-160 128c-13.1 0-25.8-1.3-37.8-3.6c-10.4-2-21.2-.6-30.7 4.2c-4.1 2.1-8.3 4.1-12.6 6c-16 7.2-32.9 13.5-49.9 18c2.8-4.6 5.4-9.1 7.9-13.6c1.1-1.9 2.2-3.9 3.2-5.9zM0 176c0 41.8 17.2 80.1 45.9 110.3c-.9 1.7-1.9 3.5-2.8 5.1c-10.3 18.4-22.3 36.5-36.6 52.1c-6.6 7-8.3 17.2-4.6 25.9C5.8 378.3 14.4 384 24 384c43 0 86.5-13.3 122.7-29.7c4.8-2.2 9.6-4.5 14.2-6.8c15.1 3 30.9 4.5 47.1 4.5c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176zM432 480c16.2 0 31.9-1.6 47.1-4.5c4.6 2.3 9.4 4.6 14.2 6.8C529.5 498.7 573 512 616 512c9.6 0 18.2-5.7 22-14.5c3.8-8.8 2-19-4.6-25.9c-14.2-15.6-26.2-33.7-36.6-52.1c-.9-1.7-1.9-3.4-2.8-5.1C622.8 384.1 640 345.8 640 304c0-94.4-87.9-171.5-198.2-175.8c4.1 15.2 6.2 31.2 6.2 47.8l0 .6c87.2 6.7 144 67.5 144 127.4c0 28-11.4 54.9-32.7 77.2c-14.3 15-17.3 37.6-7.5 55.8c1.1 2 2.2 4 3.2 5.9c2.5 4.5 5.2 9 7.9 13.6c-17-4.5-33.9-10.7-49.9-18c-4.3-1.9-8.5-3.9-12.6-6c-9.5-4.8-20.3-6.2-30.7-4.2c-12.1 2.4-24.7 3.6-37.8 3.6c-61.7 0-110-26.5-136.8-62.3c-16 5.4-32.8 9.4-50 11.8C279 439.8 350 480 432 480z"></path></svg>',
                            "Comments",
                            userId
                        )
                        : createButton(
                            "comments",
                            chatSectionToggleClose,
                            '<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="comments" class="svg-inline--fa fa-comments " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M88.2 309.1c9.8-18.3 6.8-40.8-7.5-55.8C59.4 230.9 48 204 48 176c0-63.5 63.8-128 160-128s160 64.5 160 128s-63.8 128-160 128c-13.1 0-25.8-1.3-37.8-3.6c-10.4-2-21.2-.6-30.7 4.2c-4.1 2.1-8.3 4.1-12.6 6c-16 7.2-32.9 13.5-49.9 18c2.8-4.6 5.4-9.1 7.9-13.6c1.1-1.9 2.2-3.9 3.2-5.9zM0 176c0 41.8 17.2 80.1 45.9 110.3c-.9 1.7-1.9 3.5-2.8 5.1c-10.3 18.4-22.3 36.5-36.6 52.1c-6.6 7-8.3 17.2-4.6 25.9C5.8 378.3 14.4 384 24 384c43 0 86.5-13.3 122.7-29.7c4.8-2.2 9.6-4.5 14.2-6.8c15.1 3 30.9 4.5 47.1 4.5c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176zM432 480c16.2 0 31.9-1.6 47.1-4.5c4.6 2.3 9.4 4.6 14.2 6.8C529.5 498.7 573 512 616 512c9.6 0 18.2-5.7 22-14.5c3.8-8.8 2-19-4.6-25.9c-14.2-15.6-26.2-33.7-36.6-52.1c-.9-1.7-1.9-3.4-2.8-5.1C622.8 384.1 640 345.8 640 304c0-94.4-87.9-171.5-198.2-175.8c4.1 15.2 6.2 31.2 6.2 47.8l0 .6c87.2 6.7 144 67.5 144 127.4c0 28-11.4 54.9-32.7 77.2c-14.3 15-17.3 37.6-7.5 55.8c1.1 2 2.2 4 3.2 5.9c2.5 4.5 5.2 9 7.9 13.6c-17-4.5-33.9-10.7-49.9-18c-4.3-1.9-8.5-3.9-12.6-6c-9.5-4.8-20.3-6.2-30.7-4.2c-12.1 2.4-24.7 3.6-37.8 3.6c-61.7 0-110-26.5-136.8-62.3c-16 5.4-32.8 9.4-50 11.8C279 439.8 350 480 432 480z"></path></svg>',
                            "Comments"
                        );
                    shareScreen === false
                        ? createButton(
                            "screen-share",
                            share_screen,
                            '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="up-right-from-square" class="svg-inline--fa fa-up-right-from-square " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32c0-17.7-14.3-32-32-32H352zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"></path></svg>',
                            "Screen Share",
                            userId
                        )
                        : createButton(
                            "screen-share",
                            stopScreenShare,
                            '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="rectangle-xmark" class="svg-inline--fa fa-rectangle-xmark " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"></path></svg>',
                            "Screen Share"
                        );

                    clearInterval(timer);
                    //   setisLoading(false);
                }
            }
        }, 3000);
    };
    const emojiToggleOpen = () => {
        setEmojiToggle(!emojiToggle);
        // console.log('11');
    };
    function createButton(id, onClick, iconHTML, title, userId) {
        // console.log(userId, "is host");
        if (
            //   allEventSpeakers.find(
            //     (item) => item?.user_id == user?.id && item.is_remove == "no"
            //   ) ||
            //   eventDetails.organizer === user?.id ||
            userId == user?.id
            //   ||
            //   invitation_accepted === true
        ) {
            const newDivElement = document.createElement("div");
            newDivElement.className = "custom-menu-button";
            newDivElement.setAttribute("data-bs-placement", "top");
            newDivElement.setAttribute("data-bs-toggle", "tooltip");
            newDivElement.setAttribute("title", title);

            const anchorElement = document.createElement("a");
            anchorElement.id = id;

            // console.log(videoCallSessionId, 'videoCallSessionId');

            if (id === "white-board") {
                // anchorElement.href = "/event-whiteBoard/test";
                anchorElement.href = "/event-whiteBoard/" + videoCallSessionId;
                anchorElement.target = "_blank";
                // anchorElement.onClick = onClick
            } else {
                anchorElement.onclick = onClick;
            }
            anchorElement.innerHTML = iconHTML;

            newDivElement.appendChild(anchorElement);
            const targetDiv = document.querySelector(
                ".video-call-container-close-chat > div > div:nth-child(2)",
                ".video-call-container > div > div:nth-child(2)",
            );
            if (targetDiv) {
                targetDiv.appendChild(newDivElement);
                targetDiv.style.width = "35em";
            }
        } else {
            if (id === "comments") {
                const newDivElement = document.createElement("div");
                newDivElement.className = "custom-menu-button";
                const anchorElement = document.createElement("a");
                anchorElement.id = id;
                anchorElement.onclick = onClick;
                anchorElement.innerHTML = iconHTML;
                newDivElement.appendChild(anchorElement);
                const targetDiv = document.querySelector(
                    ".video-call-container-close-chat > div > div:nth-child(2)",
                    ".video-call-container > div > div:nth-child(2)",
                );
                if (targetDiv) {
                    targetDiv.appendChild(newDivElement);
                    targetDiv.style.width = "12em";
                }
            }
        }
    }
    const chatSectionToggleClose = () => {
        // console.log("Close");
        setChatOpen(false);
        const targetDiv = document.getElementById("comments");
        if (targetDiv) {
            targetDiv.onclick = chatSectionToggle;
        }
    };
    const chatSectionToggle = () => {
        setChatOpen(true);
        const targetDiv = document.getElementById("comments");
        if (targetDiv) {
            targetDiv.onclick = chatSectionToggleClose;
        }
        chatAutoScroll();
    };
    const chatAutoScroll = () => {
        setTimeout(() => {
            var scrollableDiv = document.getElementById("stream-chat-section");
            if (scrollableDiv) {
                scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
            }
        }, 1000); // Adjust the delay as needed
    };
    const writeUserData = (type) => {
        const db = getDatabase();
        const commentRef = ref(db, "BroadComments/" + channelName);
        let send = push(commentRef, {
            au_id: user.coach_id ? user.coach_id : user?.user_id,
            au_image: user?.profile_pic,
            au_message: type === "heart" ? "" : message,
            au_name: user?.name,
            channelName: channelName,
            msg_type: type,
        });
        // console.log(send);
        setMessage("");
        fetchComments(channelName);
        chatAutoScroll();
        setEmojiToggle(false);
    };
    return (
        // <>
        // {
        //     console.log(videoCall)
        // }
        // {
        videoCall === false ? (
            // <PulseLoader color="#36D7B7" loading={loading} size={15} />
            <div
                style={{
                    // textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    width: "100vw",
                }}
            >
                <p>
                    <PulseLoader color="#00504E" size={20} />
                </p>
            </div>
        ) : (
            <>
                <Modal visible={videoCall} width="100%" height="100%">
                    {videoCall === true && agoraToken !== null && (
                        <div
                            style={styles.videoContainer}
                            className={`position-relative ${chatOpen === false
                                ? "video-call-container-close-chat"
                                : "video-call-container"
                                }`}
                        >
                            {
                                agoraToken !== "" && rtmToken !== "" ?
                                    <AgoraUIKit
                                        rtcProps={{
                                            appId: app_id,
                                            channel: channelName,
                                            token: agoraToken,
                                            uid: user?.userType === "coach" ? `${user?.coach_id}` : `${user?.user_id}`,
                                            // role: user?.userType === "user" ? "audience" : "host",
                                            role: "host",
                                            layout: videoLayout,
                                            // layout: layout.grid,
                                            // enableScreensharing: true,
                                            // screenshareUid: 9999,
                                            // screenshareToken: screenSHareToken,
                                            // background: backgroundImage, // Set the virtual background image
                                            // processor: processor, // Pass the virtual background processor
                                            // enableDualStream: true,
                                            dualStreamMode: 0,
                                        }}
                                        rtmProps={{
                                            username: user?.name,
                                            displayUsername: true,
                                            token: rtmToken,
                                            showPopUpBeforeRemoteMute: false,
                                        }}
                                        callbacks={props.callbacks}
                                        styleProps={styles.styleProps}
                                    />
                                    : ""
                            }
                        </div>
                    )}
                    <div
                        className={`event_comment_list ${chatOpen === false ? "event-comment-list-close-chat" : ""
                            }`}
                    >
                        <div className="live_compo_container h-100">
                            <div className="h-100">
                                <div className="h-100 d-flex align-items-start">
                                    <div
                                        className="tab-content p-3 w-100 h-100"
                                        id=""
                                        style={{ position: "relative" }}
                                    >
                                        <div>
                                            <ul
                                                className="pub_pri_nav nav nav-pills nav-fill mb-3"
                                                id="pills-tab"
                                                role="tablist"
                                            >
                                                <li className="nav-item z-index-1 position-relative" role="presentation">
                                                    <button
                                                        className="nav-link active"
                                                        id="pills-public-tab"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#pills-public"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="pills-public"
                                                        aria-selected="true"
                                                    >
                                                        Public
                                                    </button>
                                                </li>
                                            </ul>
                                            <div>

                                                {/* <div className="event_comments_cntr" ref={listInnerRef} id="stream-chat-section"> */}
                                                <div
                                                    className="event_comments_cntr"
                                                    id="stream-chat-section"
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column-reverse",
                                                    }}
                                                >
                                                    {
                                                        console.log(allComments, 'allComments')
                                                    }
                                                    {allComments.length > 0 &&
                                                        allComments.map((item) => (
                                                            <div className="event_comments mt-2 align-items-center">
                                                                {/* <LazyLoad>
                                                                <img src={item.au_image} alt="" />
                                                                </LazyLoad> */}
                                                                {
                                                                    item.au_image ?
                                                                        <img
                                                                            width={"50px"}
                                                                            height={"50px"}
                                                                            alt="user pic"
                                                                            className="object-fit-cover rounded-circle user_img me-2 position-relative z-2"
                                                                            src={item.au_image}
                                                                            onError={(e) =>
                                                                            (e.target.src =
                                                                                "https://thumbs.dreamstime.com/b/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg")
                                                                            }
                                                                        />
                                                                        :
                                                                        <img
                                                                            width={"50px"}
                                                                            height={"50px"}
                                                                            alt="user pic"
                                                                            className="object-fit-cover rounded-circle user_img me-2 position-relative z-2"
                                                                            src="https://thumbs.dreamstime.com/b/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg"
                                                                        />
                                                                }
                                                                <div className="commentator_name">
                                                                    <h6 className="text_light">
                                                                        {" "}
                                                                        {item.au_name}{" "}
                                                                    </h6>
                                                                    {item.msg_type === "text" ? (
                                                                        <p className="fs-12 m-0">
                                                                            {" "}
                                                                            {item.au_message}{" "}
                                                                        </p>
                                                                    ) : (
                                                                        <img
                                                                            src={like}
                                                                            className="heart_button mb-4"
                                                                            alt=""
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                                {hideCommentBox === false && (
                                                    <div className="event_comments_section position-absolute">
                                                        <div className="send_message_form px-3">
                                                            {/* <form onSubmit={ (e)=> handleSubmit(e)}> */}
                                                            <input
                                                                className="messageInput"
                                                                type="text"
                                                                placeholder="Write your views"
                                                                value={message}
                                                                onChange={sendComment}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === "Enter") {
                                                                        // console.log(e.key, "key name");
                                                                        writeUserData("text");
                                                                    }
                                                                }}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={faPaperPlane}
                                                                className="sendButton"
                                                                onClick={() => writeUserData("text")}
                                                            />
                                                            {/* <FontAwesomeIcon
                                                            icon={faThumbsUp}
                                                            className="heart_button"
                                                            onClick={() => writeUserData("heart")}
                                                        /> */}
                                                            <FontAwesomeIcon
                                                                icon={faFaceSmileBeam}
                                                                className="heart_button"
                                                                onClick={() => emojiToggleOpen()}
                                                            />
                                                            {emojiToggle === true ? (
                                                                // <EmojiPicker onEmojiSelect={() => handleEmojiSelect()} />
                                                                <EmojiPicker
                                                                    pickerStyle={{ width: '100%' }}
                                                                    onEmojiClick={onEmojiClick}
                                                                    ref={emojiRef} // Optional reference (if needed)
                                                                />
                                                                // <Picker
                                                                //     pickerStyle={{ width: "100%" }}
                                                                //     onEmojiClick={onEmojiClick}
                                                                // />
                                                            ) : (
                                                                ""
                                                            )}
                                                            {/* </form> */}
                                                        </div>
                                                    </div>
                                                )}
                                                {/* <div className="light_bg_search input-group flex-nowrap">
                                                <span className="input-group-text" id="">
                                                    <svg
                                                        width="18"
                                                        height="18"
                                                        viewBox="0 0 27 27"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            // fill-rule="evenodd"
                                                            // clip-rule="evenodd"
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M12.8172 0C19.7854 0 25.4535 5.66801 25.4535 12.6362C25.4535 15.9238 24.1918 18.9223 22.1272 21.1726L26.1897 25.2267C26.5699 25.6069 26.5712 26.2219 26.191 26.6021C26.0016 26.7942 25.7511 26.8889 25.502 26.8889C25.2541 26.8889 25.005 26.7942 24.8142 26.6047L20.7027 22.5047C18.5398 24.2368 15.7974 25.2738 12.8172 25.2738C5.849 25.2738 0.179688 19.6045 0.179688 12.6362C0.179688 5.66801 5.849 0 12.8172 0ZM12.8172 1.94643C6.92213 1.94643 2.12612 6.74114 2.12612 12.6362C2.12612 18.5313 6.92213 23.3273 12.8172 23.3273C18.711 23.3273 23.507 18.5313 23.507 12.6362C23.507 6.74114 18.711 1.94643 12.8172 1.94643Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </span>
                                                <input
                                                    className="form-control ps-0"
                                                    type="text"
                                                    placeholder="Search"
                                                />
                                            </div> */}
                                                {/* <div
                                                className="event_comments_cntr"
                                                id="stream-chat-section"
                                            >
                                                {allComments.length > 0 &&
                                                    allComments.map((item) => (
                                                        <div className="event_comments mt-2 align-items-center">
                                                            {
                                                                item.au_image ?
                                                                    <img
                                                                        width={"50px"}
                                                                        height={"50px"}
                                                                        alt="user pic"
                                                                        className="object-fit-cover rounded-circle user_img me-2 position-relative z-2"
                                                                        src={item.au_image}
                                                                        onError={(e) =>
                                                                        (e.target.src =
                                                                            "https://thumbs.dreamstime.com/b/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg")
                                                                        }
                                                                    />
                                                                    :
                                                                    <img
                                                                        width={"50px"}
                                                                        height={"50px"}
                                                                        alt="user pic"
                                                                        className="object-fit-cover rounded-circle user_img me-2 position-relative z-2"
                                                                        src="https://thumbs.dreamstime.com/b/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg"
                                                                    />
                                                            }
                                                            <div className="commentator_name">
                                                                <h6 className="text_light">
                                                                    {" "}
                                                                    {item.au_name}{" "}
                                                                </h6>
                                                                {item.msg_type === "text" ? (
                                                                    <p className="fs-12 m-0">
                                                                        {" "}
                                                                        {item.au_message}{" "}
                                                                    </p>
                                                                ) : (
                                                                    <img
                                                                        src={like}
                                                                        alt=""
                                                                        className="heart_button mb-4"
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                <div className="event_self_comments">
                                                    <p className="fs-12 m-0">Hello There</p>
                                                </div>
                                            </div> */}
                                                {hideCommentBox === false && (
                                                    <div className="event_comments_section position-absolute">
                                                        <div className="send_message_form px-3">
                                                            <input
                                                                className="messageInput"
                                                                type="text"
                                                                placeholder="Write your views"
                                                                value={message}
                                                                onChange={sendComment}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={faPaperPlane}
                                                                className="sendButton"
                                                                onClick={() => writeUserData("text")}
                                                            />
                                                            <FontAwesomeIcon
                                                                icon={faFaceSmileBeam}
                                                                className="heart_button"
                                                                onClick={() => emojiToggleOpen()}
                                                            />
                                                            {emojiToggle === true ? (
                                                                // <Picker
                                                                //     pickerStyle={{ width: "100%" }}
                                                                //     onEmojiClick={onEmojiClick}
                                                                // />
                                                                <EmojiPicker
                                                                    pickerStyle={{ width: '100%' }}
                                                                    onEmojiClick={onEmojiClick}
                                                                    ref={emojiRef} // Optional reference (if needed)
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        )
        // </>
    );
}

export default LiveStreamComponent;
