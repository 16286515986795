import React, { useState, useEffect } from "react";
import { Badge, Button, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import dummy_coach1_sqr from "../../../assets/images/dummy/dummy_coach1_sqr.png";
import banner from "../../../assets/images/coach_details_banner.png";
import share from "../../../assets/images/icons/share.png";
import ex_years_grn from "../../../assets/images/icons/ex_years_grn.png";
import degree_grn from "../../../assets/images/icons/degree_grn.png";
import language_grn from "../../../assets/images/icons/language_grn.png";
import loction_grn from "../../../assets/images/icons/loction_grn.png";
import CoachDetailsAboutComponent from "./Details/about";
import CoachDetailsSocialFeedsComponent from "./Details/socialFeeds";
import CoachDetailsShortVideosComponent from "./Details/shortVideos";
import CoachDetailsFreeLiveSessionComponent from "./Details/freeLiveSession";
import CoachDetailsCaseStudiesComponent from "./Details/caseStudies";
import CoachDetailsBlogComponent from "./Details/blogs";
import CoachDetailsAboutMobScreenComponent from "./Details/about_mob";
import { toast } from "react-toastify";
import {
  followUnfollowCoach,
  getCoachById,
  sendMessage,
} from "../../../utils/api";
import Loader from "../../../Coach/Common/Loader";
import { useUserContext } from "../../../utils/hooks/UserContext";
import { getBadgeColor } from "../../../utils/functions";
import LoginComponent from "../Login/newindex";
import { Helmet } from "react-helmet";

function CoachDetailsComponent() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useUserContext();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [isFollow, setIsFollow] = useState(false);
  const [loginModalShow, setLoginModalShow] = React.useState(false);

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await getCoachById(id);
      setUserData(data?.data);
      if (data?.data?.is_following === 1) {
        setIsFollow(true);
      } else if (data?.data?.is_following === 0) {
        setIsFollow(false);
      }
    } catch (error) {
      console.info("error++ ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    
    const canonicalUrl = window.location.origin + window.location.pathname;
    let link = document.querySelector("link[rel='canonical']");
    if (!link) {
    link = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    document.head.appendChild(link);
    }
    link.setAttribute('href', canonicalUrl);
    
    getData();
    window.scrollTo(0, 0);
  }, []);

  const onClickFollow = async () => {
    if (!user) {
      setLoginModalShow(true);
      toast.error("Please login to do this action");
      return;
    }
    // setIsLoading(true)
    const formdata = new FormData();
    formdata.append("coach_id", userData?.coach_id);

    let data = await followUnfollowCoach(formdata);
    if (data.data.status === true) {
      // if(userData?.is_following === 1){
      //     setIsFollow(1);
      // }else if(userData?.is_following === 0){
      //     setIsFollow(0);
      // }
      setIsFollow(!isFollow); // Toggle isFollow value
    }
  };

  const send_message = async () => {
    if (user?.userType !== "user") {
      //   navigate("/");
      toast.error("Please login to do this action");
      return;
    }
    const data = {
      coach_id: userData?.coach_id,
      message: "Hello Sir",
    };
    const response = await sendMessage(data);
    localStorage.setItem(
      "chatOpen",
      JSON.stringify({
        status: true,
        coach_id: userData?.coach_id,
      })
    );
    if (response.data.status === true) {
      navigate("/user/messages");
    }
  };

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en" }}
        title="Discover Your Perfect Match | Explore Coach Profiles on Qoach"
        meta={[
          {
            name: "description",
            content:
              "Get to know your coach on a deeper level with Qoach's detailed profile pages. From expertise and experience to coaching approach and testimonials, find all the information you need to make an informed decision about your coaching journey. Start building a meaningful connection with your coach today!",
          },
        ]}
      />
      <div className="d-none d-sm-block coach-detail-page">
        <section className="white_bg py-4">
          <Container>
            <Button
              className="px-3"
              variant="page_back"
              onClick={() => navigate(-1)}
            >
              <svg
                className="me-3"
                width="13"
                height="24"
                viewBox="0 0 13 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.3965 1.49949C12.3961 1.89718 12.2379 2.27846 11.9565 2.55949L4.28448 10.2315C4.05227 10.4636 3.86806 10.7393 3.74238 11.0426C3.6167 11.346 3.55202 11.6711 3.55202 11.9995C3.55202 12.3279 3.6167 12.653 3.74238 12.9564C3.86806 13.2597 4.05227 13.5353 4.28448 13.7675L11.9465 21.4345C12.2197 21.7174 12.3709 22.0963 12.3675 22.4896C12.3641 22.8829 12.2063 23.2591 11.9282 23.5372C11.6501 23.8153 11.2739 23.9731 10.8806 23.9765C10.4873 23.9799 10.1084 23.8287 9.82548 23.5555L2.16348 15.8945C1.13325 14.8622 0.554641 13.4634 0.554641 12.005C0.554641 10.5466 1.13325 9.14776 2.16348 8.11549L9.83548 0.443495C10.045 0.233877 10.3118 0.0910072 10.6024 0.0329132C10.893 -0.0251808 11.1943 0.00410271 11.4683 0.117071C11.7423 0.23004 11.9766 0.421629 12.1418 0.667664C12.307 0.913698 12.3956 1.20315 12.3965 1.49949Z"
                  fill="#14183E"
                />
              </svg>
              Back
            </Button>
          </Container>
        </section>

        {isLoading && <Loader />}

        {!isLoading && (
          <>
            <section className="white_bg pb-4">
              <Container>
                <div className="coach_details_banner">
                  <div className="coach_details_banner-img">
                    {userData?.background_img && (
                      <img
                        className="profile_banner_img"
                        src={`${process.env.REACT_APP_COACH_BG_FOLDER_PATH}${userData?.background_img}`}
                        alt=""
                      />
                    )}
                    {!userData?.background_img && (
                      <img className="profile_banner_img" src={banner} alt="" />
                    )}
                  </div>
                  <div className="coach_details_banner_content">
                    <Row className="align-items-end justify-content-between">
                      <Col>
                        <div>
                          {userData?.profile_pic && (
                            <img
                              className="coach_details_img"
                              src={`${process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH}${userData?.profile_pic}`}
                              alt=""
                            />
                          )}
                          {!userData?.profile_pic && (
                            <img
                              className="coach_details_img"
                              src={dummy_coach1_sqr}
                              alt=""
                            />
                          )}
                        </div>
                      </Col>
                      <Col className="col-auto">
                        <div className="position-relative">
                          <NavLink>
                            <img src={share} alt="" />
                          </NavLink>
                          {userData?.coach_type && (
                            <Badge
                              className={`bcl_badge_type ${getBadgeColor(
                                userData?.coach_type
                              )}`}
                            >
                              {userData?.coach_type}
                            </Badge>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-auto col-auto col-lg-4">
                        <div className="coach_details">
                          <h4>{userData?.name}</h4>
                          <div className="coach_details_label mb-3">
                            {userData?.skills?.map((i) => {
                              return <label>{i?.name}</label>;
                            })}
                          </div>
                        </div>
                      </Col>
                      <Col className="col-auto col-lg-8">
                        <Row className="d-flex align-items-center mt-3 justify-content-end">
                          {/* <Col>
                                                        <Row className="ydm_row align-items-center justify-content-between">
                                                            <Col className="col-auto text-center">
                                                                <img height="30" src={ex_years_grn} alt="" />
                                                                <p className="mt-2">10 Years of Experience</p>
                                                            </Col>
                                                            <Col className="col-auto text-center">
                                                                <img height="30" src={degree_grn} alt="" />
                                                                <p className="mt-2">10k People coached</p>
                                                            </Col>
                                                            <Col className="col-auto text-center">
                                                                <img height="30" src={language_grn} alt="" />
                                                                <p className="mt-2">{userData?.languages?.map((i) => <>{i?.name},</>)}</p>
                                                            </Col>
                                                            <Col className="col-auto text-center">
                                                                <img height="30" src={loction_grn} alt="" />
                                                                <p className="mt-2">{userData?.state_name || "-"},{userData?.country_name || "-"}</p>
                                                            </Col>
                                                        </Row>
                                                    </Col> */}
                          <Col className="col-auto">
                            {console.log(user, "userData")}

                            {/* {userData?.is_following === 1 && <Button onClick={onClickFollow} disabled={isLoading} variant="outline-success" size="md">UNFOLLOW</Button>}
                                                        {userData?.is_following === 0 && <Button onClick={onClickFollow} disabled={isLoading} variant="success" size="md">FOLLOW</Button>} */}
                            {console.log(isFollow, "isFollow")}
                            {isFollow === true && (
                              <Button
                                onClick={onClickFollow}
                                disabled={isLoading}
                                variant="outline-success"
                                size="md"
                              >
                                UNFOLLOW
                              </Button>
                            )}
                            {isFollow === false && (
                              <Button
                                onClick={onClickFollow}
                                disabled={isLoading}
                                variant="success"
                                size="md"
                              >
                                FOLLOW
                              </Button>
                            )}
                            {isFollow === true && (
                              <Button
                                variant="success"
                                className="ms-3"
                                size="md"
                                onClick={send_message}
                              >
                                Message
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </section>
            <section className="white_bg pb-4">
              <Container>
                {
                  console.log(userData, 'userData')
                }
                <Row className="ydm_row align-items-center justify-content-between">
                  <Col className="col-auto text-center">
                    <img height="30" src={ex_years_grn} alt="" />
                    <p className="mt-2">{userData? userData.workexperience?? "0" : '0'} Years of Experience</p>
                  </Col>
                  <Col className="col-auto text-center">
                    <img height="30" src={degree_grn} alt="" />
                    <p className="mt-2">{userData? userData.is_following ?? '0' : '0'} People coached</p>
                  </Col>
                  <Col className="col-auto text-center">
                    <img height="30" src={language_grn} alt="" />
                    <p className="mt-2">
                      {userData
                        ? userData?.languages
                          ? userData?.languages?.map((i) => <>{i?.name},</>)
                          : "---"
                        : "---"}
                    </p>
                  </Col>
                  <Col className="col-auto text-center">
                    <img height="30" src={loction_grn} alt="" />
                    <p className="mt-2">
                      {userData?.state_name || "-"},
                      {userData?.country_name || "-"}
                    </p>
                  </Col>
                </Row>
              </Container>
            </section>
            <section className="white_bg">
              <Tab.Container id="" defaultActiveKey="about">
                <Nav
                  variant="pills"
                  className="tab_nav_link justify-content-center"
                >
                  <Nav.Item>
                    <Nav.Link eventKey="about">About</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="social_feeds">Social Feeds</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="short_videos">Short Videos</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="free_live_session">
                      Free Live Session
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="case_studies">Case Studies</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="blogs">Blogs</Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content className="mt-4">
                  <Tab.Pane eventKey="about">
                    <CoachDetailsAboutComponent userData={userData} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="social_feeds">
                    <CoachDetailsSocialFeedsComponent userData={userData} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="short_videos">
                    <CoachDetailsShortVideosComponent userData={userData} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="free_live_session">
                    <CoachDetailsFreeLiveSessionComponent userData={userData} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="case_studies">
                    <CoachDetailsCaseStudiesComponent userData={userData} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="blogs">
                    <CoachDetailsBlogComponent userData={userData} />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </section>
          </>
        )}
      </div>
      <div>
        <section className="white_bg d-block d-sm-none">
          <Tab.Container id="" defaultActiveKey="about">
            {isLoading && <Loader />}

            {!isLoading && (
              <>
                <Container>
                  <Row className="g-1 justify-content-between align-items-center">
                    <Col xs="auto">
                      <Button
                        className="p-0"
                        variant=""
                        onClick={() => navigate(-1)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_2233_4205)">
                            <path
                              d="M1.23333 8.25874L4.45833 5.0004C4.5358 4.9223 4.62797 4.8603 4.72952 4.818C4.83107 4.77569 4.93999 4.75391 5.05 4.75391C5.16001 4.75391 5.26893 4.77569 5.37048 4.818C5.47203 4.8603 5.5642 4.9223 5.64167 5.0004C5.79688 5.15654 5.88399 5.36775 5.88399 5.5879C5.88399 5.80806 5.79688 6.01927 5.64167 6.1754L2.675 9.16707H19.6667C19.8877 9.16707 20.0996 9.25487 20.2559 9.41115C20.4122 9.56743 20.5 9.77939 20.5 10.0004C20.5 10.2214 20.4122 10.4334 20.2559 10.5897C20.0996 10.7459 19.8877 10.8337 19.6667 10.8337H2.625L5.64167 13.8421C5.71977 13.9195 5.78177 14.0117 5.82408 14.1133C5.86638 14.2148 5.88816 14.3237 5.88816 14.4337C5.88816 14.5437 5.86638 14.6527 5.82408 14.7542C5.78177 14.8558 5.71977 14.9479 5.64167 15.0254C5.5642 15.1035 5.47203 15.1655 5.37048 15.2078C5.26893 15.2501 5.16001 15.2719 5.05 15.2719C4.93999 15.2719 4.83107 15.2501 4.72952 15.2078C4.62797 15.1655 4.5358 15.1035 4.45833 15.0254L1.23333 11.7921C0.765163 11.3233 0.502199 10.6879 0.502199 10.0254C0.502199 9.3629 0.765163 8.72749 1.23333 8.25874Z"
                              fill="#14183E"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2233_4205">
                              <rect
                                width="20"
                                height="20"
                                fill="white"
                                transform="matrix(-1 0 0 1 20.5 0)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </Button>
                    </Col>
                    <Col xs="auto">
                      <Nav
                        variant="pills"
                        className="tab_nav_link justify-content-center"
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="about">About</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="social_feeds">
                            Social Feeds
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="short_videos">
                            Short Videos
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="free_live_session">
                            Free Live Session
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="case_studies">
                            Case Studies
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="blogs">Blogs</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                </Container>
                <Container>
                  <div className="coach_details_mob_banner">
                    <div className="">
                      <Row className="align-items-center justify-content-between">
                        <Col xs="6">
                          <div>
                            {userData?.profile_pic && (
                              <img
                                className="coach_details_img"
                                src={`${process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH}${userData?.profile_pic}`}
                                alt=""
                              />
                            )}
                            {!userData?.profile_pic && (
                              <img
                                className="coach_details_img"
                                src={dummy_coach1_sqr}
                                alt=""
                              />
                            )}
                          </div>
                        </Col>
                        <Col xs="6" className="col-auto">
                          <div className="position-relative">
                            <NavLink>
                              <img src={share} alt="" />
                            </NavLink>
                            <Badge className="bcl_badge_type purple">
                              Master
                            </Badge>
                          </div>
                        </Col>
                      </Row>
                      <Row className="g-0">
                        <Col sm="12">
                           
                          <div className="coach_details">
                            <h4 className="fs-20 fw-600">{user?.name}</h4>
                            <div className="coach_details_label mb-3">
                              {userData?.tags?.map((i) => {
                                return <label>{i}</label>;
                              })}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <Row className="">
                            <Col>
                              <Row className="mt-0 ydm_row align-items-center justify-content-between">
                                <Col xs="6" className="text-center">
                                  <img height="30" src={ex_years_grn} alt="" />
                                  <p className="my-2">10 Years of Experience</p>
                                </Col>
                                <Col xs="6" className="text-center">
                                  <img height="30" src={degree_grn} alt="" />
                                  <p className="my-2">10k People coached</p>
                                </Col>
                                <Col xs="6" className="text-center">
                                  <img height="30" src={language_grn} alt="" />
                                  <p className="my-2">
                                    {userData?.languages?.map((i) => (
                                      <>{i?.name},</>
                                    ))}
                                  </p>
                                </Col>
                                <Col xs="6" className="text-center">
                                  <img height="30" src={loction_grn} alt="" />
                                  <p className="my-2">
                                    {userData?.state_name || "-"},
                                    {userData?.country_name || "-"}
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                            <Col sm="12" className="text-center">
                              <Button
                                onClick={onClickFollow}
                                disabled={isLoading}
                                variant="success"
                                size="md"
                              >
                                {isFollow === true ? "UNFOLLOW" : "FOLLOW"}
                              </Button>
                              {/* <Button onClick={onClickFollow} disabled={isLoading} variant="success" size="md">{userData?.is_following === 1 ? "UNFOLLOW" : "FOLLOW"}</Button> */}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Container>
              </>
            )}

            {!isLoading && (
              <Tab.Content className="mt-4">
                <Tab.Pane eventKey="about">
                  <div className="d-none d-sm-block">
                    <CoachDetailsAboutComponent userData={userData} />
                  </div>
                  <div className="d-block d-sm-none">
                    <CoachDetailsAboutMobScreenComponent userData={userData} />
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="social_feeds">
                  <CoachDetailsSocialFeedsComponent userData={userData} />
                </Tab.Pane>

                <Tab.Pane eventKey="short_videos">
                  <CoachDetailsShortVideosComponent userData={userData} />
                </Tab.Pane>

                <Tab.Pane eventKey="free_live_session">
                  <CoachDetailsFreeLiveSessionComponent userData={userData} />
                </Tab.Pane>

                <Tab.Pane eventKey="case_studies">
                  <CoachDetailsCaseStudiesComponent userData={userData} />
                </Tab.Pane>
                <Tab.Pane eventKey="blogs">
                  <CoachDetailsBlogComponent userData={userData} />
                </Tab.Pane>
              </Tab.Content>
            )}
          </Tab.Container>
        </section>
      </div>
      <LoginComponent
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
      />
    </>
  );
}

export default CoachDetailsComponent;
