
import React from 'react';

const ProgramSessionRules = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <h1>Coaching Program Instructions</h1>

      <h2>Program Overview</h2>
      <p>
        Welcome to our Coaching Program! This program is designed to help you achieve your personal and professional goals through one-on-one coaching sessions.
      </p>

      <h2>Getting Started</h2>
      <ol>
        <li>
          <strong>Sign Up:</strong> Complete the registration form on our website to enroll in the coaching program.
        </li>
        <li>
          <strong>Initial Assessment:</strong> After signing up, you will receive an assessment form to understand your goals and needs.
        </li>
        <li>
          <strong>Schedule Your Sessions:</strong> Based on your assessment, our team will help you schedule your coaching sessions at convenient times.
        </li>
      </ol>

      <h2>Session Guidelines</h2>
      <ul>
        <li>
          <strong>Be Prepared:</strong> Come to each session with specific goals and topics you want to discuss.
        </li>
        <li>
          <strong>Stay Committed:</strong> Regular attendance is crucial for progress. If you need to reschedule, give at least 24 hours' notice.
        </li>
        <li>
          <strong>Open Communication:</strong> Feel free to share your thoughts and feedback during the sessions.
        </li>
      </ul>

      <h2>Resources</h2>
      <p>
        You will have access to a variety of resources, including articles, videos, and worksheets, to support your coaching journey.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions or need assistance, please reach out to our support team at support@example.com.
      </p>

      <h2>Conclusion</h2>
      <p>
        We are excited to have you in our Coaching Program! Together, we will work towards achieving your goals and unlocking your potential.
      </p>
    </div>
  );
};

export default ProgramSessionRules;

