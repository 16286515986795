import React, { useState,useEffect } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import view from "../../../assets/images/icons/view.png";
import hidden from "../../../assets/images/icons/hidden.png";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { userUpdate } from "../../../utils/api";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import Pic from "../../../assets/images/icons/user.png";
import blog_card_img1 from "../../../assets/images/dummy/blog_card_img1.png";
import { useUserContext } from "../../../utils/hooks/UserContext";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';
import moment from "moment";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import SessionCss from '../../UserCss/session.css'

const baseURL = `${process.env.REACT_APP_LOCAL_BASE_URL}`;
const UserScheduleSessionComponent = () => {
    const navigate = useNavigate();

    const location = useLocation();
    console.log("dddd",location)
    const { sessionData } = location.state || {};

    const { user } = useUserContext();
    const [bookedEvents, setBookedEvents] = useState([]);
    const [myCoachList, setMyCoachList] = useState([]);
    const [myProgramList, setMyProgramList] = useState([]);

    const [selectedDate, setSelectedDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const today = new Date();
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);

  
    const getData = async () => {
      try {

        const listOption1= {
            method: "GET",
            headers: {
              "content-type": "application/json",
              token: localStorage.getItem("token"),
              "Cache-Control":
                "no-store, no-cache, must-revalidate, proxy-revalidate",
            },
    
            url: `${baseURL}/coach/program/users-slot-register-list?coach_id=${sessionData.coach_id}&program_id=${sessionData.coach_program_id}`,
          };
          axios(listOption1).then((resp) => {
            console.log(resp, "users-slot-register-list");
            setMyProgramList(resp.data.data);
          });


        const listOptions = {
          method: "GET",
          headers: {
            "content-type": "application/json",
            token: localStorage.getItem("token"),
            "Cache-Control":
              "no-store, no-cache, must-revalidate, proxy-revalidate",
          },
  
          url: `${baseURL}/coach/program/users-coach-list`,
        };
        axios(listOptions).then((resp) => {
          console.log(resp, "resp");
          setMyCoachList(resp.data.data);
        });
  
        //   console.log(userSessions, "sessions"); // Check the response structure
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const getListData = async () => {
        console.log("useruseruseruser",user)
        try {
            const options = {
              method: "GET",
              headers: {
                "content-type": "application/json",
                token: localStorage.getItem("token"),
                "Cache-Control":
                  "no-store, no-cache, must-revalidate, proxy-revalidate",
              },
      
              url: `${baseURL}/coach/program/coach-booked-slot-list?type=user&coach_id=${sessionData.coach_id}`,
            };
            axios(options).then((resp) => {
              console.log(resp, "filter");  
              if(resp.data.data.length > 0){
                // resp.data.data = resp.data.data.map(element => {
                //   element.start_time= moment(element.start_time , "YYYY-MM-DD HH:mm:ss") // Parse the string
                //   .add(30, "minutes") // Add 40 minutes
                //   .add(6, "hours") // Add 2 hours
                //   .format('YYYY-MM-DD HH:mm:ss');
                //   element.end_time= moment(element.end_time , "YYYY-MM-DD HH:mm:ss") // Parse the string
                //   .add(30, "minutes") // Add 40 minutes
                //   .add(6, "hours") // Add 2 hours
                //   .format('YYYY-MM-DD HH:mm:ss');
                // });
                setBookedEvents(resp.data.data);
              }
              filterDataByDate(startDate);
            });
          } catch (error) {
            console.log(error);
          }
    };
    const [availabilityId, setAvailabilityId] = useState('');
    const [programId, setProgramId] = useState('');
    const [coachId, setCoachId] = useState('');
    const [slotId, setSlotId] = useState('');
    const [scheduleDate, setScheduleDate] = useState('');
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [selectedItemIdSlot, setSelectedItemIdSlot] = useState(null);

    const programSelection = async(availabilityId,programId,coachId) => {
        setAvailabilityId(availabilityId)
        setSelectedItemId(availabilityId); 
        setProgramId(programId)
        setCoachId(coachId)
        setSlotId('')
        setScheduleDate('')
    }

    const selectTimeSlot = async(slot_id,date) => {
        setSlotId(slot_id)
        setSelectedItemIdSlot(slot_id); 
     
        let s_date = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
        // console.log("Original UTC Date: ", moment.utc(date).format('YYYY-MM-DD HH:mm:ss'));
        // console.log("Adjusted Local Date: ", parsedDate.format('YYYY-MM-DD HH:mm:ss'));
        setScheduleDate(s_date)
    }
    const bookSlot = async(event) => {
        event.preventDefault();
        console.log(availabilityId ,
            programId ,
            coachId,scheduleDate,
            slotId)
        if(availabilityId == '' || 
            programId == '' ||
            coachId == '' ||
            slotId == ''
        ){
            toast.error("please select program and slots")
        }else{
        //    let scheduleDates = moment(scheduleDate , "YYYY-MM-DD HH:mm:ss") // Parse the string
        //     .add(30, "minutes") // Add 40 minutes
        //     .add(6, "hours") // Add 2 hours
        //     .format('YYYY-MM-DD HH:mm:ss');
            try {
                const options = {
                  method: "POST",
                  headers: {
                    "content-type": "application/json",
                    token: localStorage.getItem("token"),
                    "Cache-Control":
                      "no-store, no-cache, must-revalidate, proxy-revalidate",
                  },
          
                  url: `${baseURL}/coach/program/user-slot-book`,
                  data: {
                    "coach_id": coachId,
                    "program_id": programId,
                    "available_slot_id": slotId,
                    "schedule_date":scheduleDate,
                    "id": availabilityId
                },
                };
                axios(options).then((resp) => {
                    console.log(resp.data,resp.data.data)
                 if(resp.data.status){
                    if(resp.data.data.length > 0){
                        setBookedEvents(resp.data.data);
                      }
                    // setBookedEvents(resp.data.data);
                    toast.success(resp.data.message)
                    setTimeout(() => {
                        navigate('/user/my-programs');
                    }, 1000);
                 }else{
                    toast.error("slot not booked")

                 }
                });
              } catch (error) {
                toast.error("slot not booked")

                console.log(error);
              }
        }
    }
    
    
    const filterDataByDate = (start) => {
        console.log(bookedEvents,start,'0000000000000')
        const formattedDate = start.toISOString().split('T')[0]; // Convert to YYYY-MM-DD
        const filtered = bookedEvents.filter(item => {
            const itemDate = new Date(item.start_time).toISOString().split('T')[0];
            return itemDate === formattedDate;
        });
        setFilteredData(filtered);
    };

    // Call filterDataByDate when the component mounts
    useEffect(() => {
        filterDataByDate(startDate);
    }, []);

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (start) {
            filterDataByDate(start); // Call the filter function with the selected start date
        } else {
            // setFilteredData(data); // Reset to original data if no date is selected
        }
    };
    console.log(filteredData,"filteredDatafilteredData")
    useEffect(() => {
      getData();
    }, []);

      useEffect(() => {
        getListData();
      }, []);
    const defaultUserImage = (e) => {
        e.target.src = blog_card_img1;
    }
    console.log(user);
    // const [startDate, setStartDate] = useState(new Date());
    // const [endDate, setEndDate] = useState(null);

    // const onChange = (dates) => {
    //     const [start, end] = dates;
    //     setStartDate(start);
    //     setEndDate(end);
    // };
    return (
        <>
            <section className="p-5 session-schedule-user">
                <div className="row">
                    <div className="col-md-6 hdng_a text-center">
                        <h6>Schedule Session</h6>
                        <h5 className="mb-4">Select Program To Book Your Time Slot</h5>
                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
            {myProgramList.length > 0 ? (
                myProgramList.map((item, index) => (
                    <li key={index} style={{ marginBottom: '10px' }}>
                        <div
                            className={`booking_user_card ${selectedItemId === item.id ? 'selected' : ''}`} // Apply 'selected' class if item is selected
                            onClick={() => programSelection(item.id, item.program_id, item.coach_id)} // Handle click
                        >
                            <Row className="align-items-center">
                                <Col sm="6">
                                    <div className="p-2 booking_user_card_lt">
                                        <div className="d-flex align-items-center">
                                            <div className="ps-2">
                                                <p>{item.session_title}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </li>
                ))
            ) : (
                <li>No programs available</li>
            )}
        </ul>
                    </div>
                    <div className="col-md-6 bg-body-tertiary py-4">
                        <Form className="">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="">
                                        <label className="form-label fs-14">Select a Date & Time</label>
                                        {/* <input type="date" data-date-inline-picker="true" className="form-control" /> */}
                                        <DatePicker
                                            selected={startDate}
                                            // onChange={onChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            // value={selectedDate} 
                                            onChange={handleDateChange} 
                                            // excludeDates={[addDays(new Date(), 1), addDays(new Date(), 5)]}
                                            selectsRange
                                            inline
                                        />
                                    </div>
                                    <div className="">
                                        <label className="form-label fs-14">Time Zone</label>
                                        <select className="form-control ms-0 fs-14">
                                            <option>Indian Standard Time (+05:30)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="">
                                    {filteredData.length > 0 ? (
                <>
                    {filteredData.map((item, index) => {
                        const adjustedTime = moment.utc(item.start_time);

                        const formattedStartTime = adjustedTime.format('h:mm A');
                            console.log("formattedStartTime",formattedStartTime,item.start_time)
                    return(
                        <div key={index}>
                            {/* Conditionally render the label only for the first item */}
                            {index === 0 && (
                                <label className="form-label fs-14">
                                   
                                    {moment(filteredData[0].start_time).format('dddd')}, {moment(filteredData[0].start_time).format('MMMM')} {moment(filteredData[0].start_time).format('DD')}
                                </label>
                            )}
                            
                            <input 
                                type="checkbox" 
                                className="d-none" 
                                id={`checkbox-${index}`} 
                                name={`checkbox-${index}`} 
                                value={formattedStartTime} 
                                onClick={() => selectTimeSlot(item.id, item.start_time)} // Update the state and call selectTimeSlot
                            />
                            <label 
                                htmlFor={`checkbox-${index}`} 
                                className={`check-box-btn ${selectedItemIdSlot === item.id ? 'selected' : ''}`} // Add 'selected' class if this checkbox is selected
                            >
                                {formattedStartTime}
                            </label>
                        </div>
                    )})}
                </>
            ) : (
                'No Slots Available'
            )}

                                        {/* <label className="form-label fs-14">Tuesday, July 16</label>
                                        <div>
                                            <input type="checkbox" className="d-none" id="eight" name="eight" value="08:00 am" />
                                            <label for="eight" className="check-box-btn"> 08:00 am</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" className="d-none" id="ten" name="ten" value="10:00 am" />
                                            <label for="ten" className="check-box-btn"> 10:00 am</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" className="d-none" id="three" name="three" value="03:00 pm" />
                                            <label for="three" className="check-box-btn"> 03:00 pm</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" className="d-none" id="four" name="four" value="04:00 pm" />
                                            <label for="four" className="check-box-btn active"> 04:00 pm</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" className="d-none" id="five" name="five" value="05:00 pm" />
                                            <label for="five" className="check-box-btn"> 05:00 pm</label>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <button className="fs-14 btn btn-success btn-md w-100 mt-2" onClick={bookSlot}>Confirm Slot</button>
                        </Form>
                    </div>
                </div>
            </section>
        </>
    );
};

export default UserScheduleSessionComponent;
