import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Container, Row } from "react-bootstrap";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ex_years from '../../../assets/images/icons/ex_years.png';
import degree from '../../../assets/images/icons/degree.png';
import $per_time from '../../../assets/images/icons/$per_time.png';
import language from '../../../assets/images/icons/language.png';
import { getCoaches } from "../../../utils/api";
import Pic from '../../../assets/images/icons/user.png';
import FrontFilterComponent from "./Filter";
import { getBadgeColor } from "../../../utils/functions";
import { followUnfollowCoach, getFilteredCoaches } from "../../../utils/api";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserContext } from "../../../utils/hooks/UserContext";
import { PulseLoader } from "react-spinners";
import LoginComponent from '../Login/index';
import { Helmet } from "react-helmet";

function BrowseCoachGridViewComponent() {
    const [coaches, setcoaches] = useState([])
    const navigate = useNavigate();
    const { user } = useUserContext();
    const [isLoading, setisLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loginModalShow, setLoginModalShow] = useState(false);

    useEffect(() => {
        
        const canonicalUrl = window.location.origin + window.location.pathname;
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
        link = document.createElement('link');
        link.setAttribute('rel', 'canonical');
        document.head.appendChild(link);
        }
        link.setAttribute('href', canonicalUrl);
        
        setisLoading(true)
        getData()
        window.scrollTo(0, 0);
    }, [])

    const getData = async () => {
        // const { data } = await getCoaches()
        // setcoaches(data?.data)
        // setLoading(false)
        setisLoading(true)
        try {
            const { data } = await getFilteredCoaches()
            if (data?.status) {
                setcoaches(data?.data)
            }
            else {
                toast.error(data?.message)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message)
            console.error("error++", error);
        } finally {
            setisLoading(false)
        }
        setLoading(false)
    }
    const removeHTMLTags = (str) => {
        if (str != null) {
            return str.replace(/<[^>]+>/g, '');
        }
    };
    const defaultUserImage = (e) => {
        e.target.src = Pic;
    }
    const onClickFollow = async (id) => {
        if (!user) {
            // navigate('/')
            setLoginModalShow(true)
            toast.error('Please login to do this action')
            return
        }

        const formdata = new FormData()
        formdata.append('coach_id', id)

        await followUnfollowCoach(formdata)
        await getData()
    }
    const likeButton = async (e) => {
        if (!user) {
            setLoginModalShow(true)
            toast.error('Please login to do this action')
            return
        }
    }
    return (
        <>
            <Helmet
                htmlAttributes={{ lang: 'en' }}
                title="Explore Expert Coaches | Find Your Perfect Match on Qoach"
                meta={[
                    { name: 'description', content: "Explore top coaches in a convenient grid format. Find your perfect match for career, wellness, or personal development coaching. Start your journey to success with Qoach today!" },
                ]}
            />
            {loading ? (
                // <PulseLoader color="#36D7B7" loading={loading} size={15} />
                <div
                    style={{
                        // textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        width: "100vw",
                    }}
                >
                    <p>
                        <PulseLoader color="#00504E" size={20} />
                    </p>
                </div>
            ) : (
                <>
                    <section className="white_bg py-3">
                        <Container>
                            <Row className="justify-content-between align-items-center">
                                <Col lg="12">
                                    <FrontFilterComponent />
                                </Col>
                                <Col className="col-auto ms-auto">
                                    <Row className="g-2">
                                        <Col>
                                            <NavLink className="list_grid_view_btn" to="/browse-coach/list-view">
                                                <svg opacity="0.6" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g>
                                                        <circle cx="20" cy="20" r="18.5" stroke="#964B00" stroke-width="3" />
                                                        <path d="M28.1751 14.3654V25.6333H11.8247V14.3654H28.1751ZM28.6751 12.041H11.3247C10.3169 12.041 9.5 12.8575 9.5 13.8654V26.1333C9.5 27.1412 10.3169 27.9582 11.3247 27.9582H28.6751C29.683 27.9582 30.5 27.1413 30.5 26.1333V13.8654C30.5 12.8574 29.6829 12.041 28.6751 12.041Z" fill="#964B00" stroke="#964B00" />
                                                    </g>
                                                </svg>
                                            </NavLink>
                                        </Col>
                                        <Col>
                                            <NavLink className="list_grid_view_btn" to="/browse-coach/grid-view">
                                                <svg opacity="0.6" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="20" cy="20" r="18.5" stroke="#964B00" stroke-width="3" />
                                                    <g clip-path="url(#clip0_2115_3853)">
                                                        <path d="M15.8333 10H13.3333C12.4493 10 11.6014 10.3512 10.9763 10.9763C10.3512 11.6014 10 12.4493 10 13.3333V15.8333C10 16.7174 10.3512 17.5652 10.9763 18.1904C11.6014 18.8155 12.4493 19.1667 13.3333 19.1667H15.8333C16.7174 19.1667 17.5652 18.8155 18.1904 18.1904C18.8155 17.5652 19.1667 16.7174 19.1667 15.8333V13.3333C19.1667 12.4493 18.8155 11.6014 18.1904 10.9763C17.5652 10.3512 16.7174 10 15.8333 10ZM17.5 15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H13.3333C12.8913 17.5 12.4674 17.3244 12.1548 17.0118C11.8423 16.6993 11.6667 16.2754 11.6667 15.8333V13.3333C11.6667 12.8913 11.8423 12.4674 12.1548 12.1548C12.4674 11.8423 12.8913 11.6667 13.3333 11.6667H15.8333C16.2754 11.6667 16.6993 11.8423 17.0118 12.1548C17.3244 12.4674 17.5 12.8913 17.5 13.3333V15.8333Z" fill="#964B00" />
                                                        <path d="M26.6673 10H24.1673C23.2833 10 22.4354 10.3512 21.8103 10.9763C21.1852 11.6014 20.834 12.4493 20.834 13.3333V15.8333C20.834 16.7174 21.1852 17.5652 21.8103 18.1904C22.4354 18.8155 23.2833 19.1667 24.1673 19.1667H26.6673C27.5514 19.1667 28.3992 18.8155 29.0243 18.1904C29.6495 17.5652 30.0007 16.7174 30.0007 15.8333V13.3333C30.0007 12.4493 29.6495 11.6014 29.0243 10.9763C28.3992 10.3512 27.5514 10 26.6673 10ZM28.334 15.8333C28.334 16.2754 28.1584 16.6993 27.8458 17.0118C27.5333 17.3244 27.1093 17.5 26.6673 17.5H24.1673C23.7253 17.5 23.3014 17.3244 22.9888 17.0118C22.6762 16.6993 22.5007 16.2754 22.5007 15.8333V13.3333C22.5007 12.8913 22.6762 12.4674 22.9888 12.1548C23.3014 11.8423 23.7253 11.6667 24.1673 11.6667H26.6673C27.1093 11.6667 27.5333 11.8423 27.8458 12.1548C28.1584 12.4674 28.334 12.8913 28.334 13.3333V15.8333Z" fill="#964B00" />
                                                        <path d="M15.8333 20.834H13.3333C12.4493 20.834 11.6014 21.1852 10.9763 21.8103C10.3512 22.4354 10 23.2833 10 24.1673V26.6673C10 27.5514 10.3512 28.3992 10.9763 29.0243C11.6014 29.6495 12.4493 30.0007 13.3333 30.0007H15.8333C16.7174 30.0007 17.5652 29.6495 18.1904 29.0243C18.8155 28.3992 19.1667 27.5514 19.1667 26.6673V24.1673C19.1667 23.2833 18.8155 22.4354 18.1904 21.8103C17.5652 21.1852 16.7174 20.834 15.8333 20.834ZM17.5 26.6673C17.5 27.1093 17.3244 27.5333 17.0118 27.8458C16.6993 28.1584 16.2754 28.334 15.8333 28.334H13.3333C12.8913 28.334 12.4674 28.1584 12.1548 27.8458C11.8423 27.5333 11.6667 27.1093 11.6667 26.6673V24.1673C11.6667 23.7253 11.8423 23.3014 12.1548 22.9888C12.4674 22.6762 12.8913 22.5007 13.3333 22.5007H15.8333C16.2754 22.5007 16.6993 22.6762 17.0118 22.9888C17.3244 23.3014 17.5 23.7253 17.5 24.1673V26.6673Z" fill="#964B00" />
                                                        <path d="M26.6673 20.834H24.1673C23.2833 20.834 22.4354 21.1852 21.8103 21.8103C21.1852 22.4354 20.834 23.2833 20.834 24.1673V26.6673C20.834 27.5514 21.1852 28.3992 21.8103 29.0243C22.4354 29.6495 23.2833 30.0007 24.1673 30.0007H26.6673C27.5514 30.0007 28.3992 29.6495 29.0243 29.0243C29.6495 28.3992 30.0007 27.5514 30.0007 26.6673V24.1673C30.0007 23.2833 29.6495 22.4354 29.0243 21.8103C28.3992 21.1852 27.5514 20.834 26.6673 20.834ZM28.334 26.6673C28.334 27.1093 28.1584 27.5333 27.8458 27.8458C27.5333 28.1584 27.1093 28.334 26.6673 28.334H24.1673C23.7253 28.334 23.3014 28.1584 22.9888 27.8458C22.6762 27.5333 22.5007 27.1093 22.5007 26.6673V24.1673C22.5007 23.7253 22.6762 23.3014 22.9888 22.9888C23.3014 22.6762 23.7253 22.5007 24.1673 22.5007H26.6673C27.1093 22.5007 27.5333 22.6762 27.8458 22.9888C28.1584 23.3014 28.334 23.7253 28.334 24.1673V26.6673Z" fill="#964B00" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2115_3853">
                                                            <rect width="20" height="20" fill="white" transform="translate(10 10)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </NavLink>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="white_bg pb-4">
                        <Container>
                            <Row className="">
                                <Col lg="9">
                                    <div className="browse_coach_grid_list_card">
                                        <Row className=" justify-content-between">
                                            {coaches?.map((i) => {
                                                return (
                                                    <Col lg="6" md="6" xxl="6" xl="6" sm="12" xs="12">
                                                        {/* <NavLink to={`/browse-coach/coach-details/${i?.coach_id}`}> */}
                                                        <NavLink to={!user ? "" : `/browse-coach/coach-details/${i?.coach_id}`} onClick={!user ? (e) => likeButton() : ''}>
                                                            <div className="browse_coach_list_card browse_coach_list_card_grid_view py-4 px-1">
                                                                <div className="browse_coach_list_card_header d-block mb-0">
                                                                    <Row className="align-items-center g-2 px-3">
                                                                        <Col lg="12" className="text-center">
                                                                            {i?.profile_pic && <img className="coach_img object-fit-cover" width="70px" height="70px" src={`${process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH}${i?.profile_pic}`} alt="" onError={(e) => defaultUserImage(e)} />}
                                                                            {!i?.profile_pic && <img className="coach_img object-fit-cover" width="70px" height="70px" src={Pic} alt="" />}
                                                                            {/* <img className="coach_img" width="100%" src={`${process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH}${i?.profile_pic}`} alt="" /> */}
                                                                        </Col>
                                                                        <Col lg="12" className="text-center">
                                                                            <div className="ms-2">
                                                                                <div className="lh-sm">
                                                                                    <h5 className="m-0 mb-2">{i.name}</h5>
                                                                                    {i?.skills?.map((i) => {
                                                                                        return (<label>{i?.name}</label>)
                                                                                    })}
                                                                                </div>
                                                                                <div className="ydm_row d-block justify-content-center my-0">
                                                                                    <div className="d-flex align-items-center my-3 mx-2 overflow-hidden justify-content-between">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <span className="me-1">
                                                                                                <img src={ex_years} alt="" />
                                                                                            </span>
                                                                                            <span className="fw-500 fs-16">
                                                                                                {i.workexperience > 0
                                                                                                    ? i.workexperience + " Years"
                                                                                                    : "0 Years"}{" "}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center">
                                                                                            <span className="me-1">
                                                                                                <img src={degree} alt="" />
                                                                                            </span>
                                                                                            <span className="fw-500 fs-16">
                                                                                                {i.followers_count ? i.followers_count : 0}{" "}
                                                                                            </span>
                                                                                        </div>
                                                                                        {/* <div className="d-flex align-items-center">
                                                                                    <span className="me-2">
                                                                                        <img src={language} alt="" />
                                                                                    </span>
                                                                                    {i?.languages?.map((i) => {
                                                                                        return <span variant="added_language" className="fw-500 fs-16">{i?.name},</span>
                                                                                    })}
                                                                                </div> */}
                                                                                        <div className="d-flex align-items-center">
                                                                                            <span className="me-2">
                                                                                                <img src={$per_time} alt="" />
                                                                                            </span>
                                                                                            <span className="fs-16 text_brand_color2 fw-600">$199</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Badge className="bcl_badge_type green">Novice</Badge>
                                                                </div>
                                                                <div className="browse_coach_list_card_mid px-3">
                                                                    {/* <p className="fs-14 fw-400 text_brand_color4 text-justify mb-2" dangerouslySetInnerHTML={{ __html: i?.about }} /> */}
                                                                    <p className="fs-14 fw-400 text_brand_color4 mb-2 text-title">
                                                                        {
                                                                            i?.about ?
                                                                                removeHTMLTags(i?.about).substring(0, 125) + '...'
                                                                                : ''
                                                                        }
                                                                    </p>
                                                                    {/* {i?.tags?.map((i) => {
                                                                return (<div className="tagged_txt d-inline-flex px-1">#{i}</div>)
                                                            })} */}

                                                                    {/* <div className="d-flex align-items-center justify-content-center mt-3">
                                                            <span className="mx-4">
                                                                <img src={sqr_rss} alt="" />
                                                            </span>
                                                            <span className="mx-4">
                                                                <img src={sqr_vid} alt="" />
                                                            </span>
                                                            <span className="mx-4">
                                                                <img src={range} alt="" />
                                                            </span>
                                                        </div> */}
                                                                </div>
                                                                <div className="browse_coach_list_card_footer">
                                                                    <Button variant="lk_cls">
                                                                        {/* <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M18.3713 0.724455L11.3455 8.52748L3.54244 1.5016C2.82419 0.854895 1.71766 0.912885 1.07095 1.63113C0.424245 2.34937 0.482235 3.4559 1.20048 4.10261L9.0035 11.1285L1.97763 18.9315C1.33092 19.6497 1.38891 20.7563 2.10715 21.403C2.8254 22.0497 3.93192 21.9917 4.57863 21.2735L11.6045 13.4704L19.4075 20.4963C20.1258 21.143 21.2323 21.085 21.879 20.3668C22.5257 19.6485 22.4677 18.542 21.7495 17.8953L13.9465 10.8694L20.9723 3.06641C21.619 2.34817 21.5611 1.24164 20.8428 0.59493C20.1246 -0.0517789 19.018 0.00621183 18.3713 0.724455Z" fill="#EA0606" />
                                                                </svg> */}
                                                                        {/* <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.32158 21.2884C8.33563 21.2851 7.60512 21.1113 7.10659 20.8208C6.60336 20.5272 6.31592 20.1262 6.18757 19.5644C5.86594 18.4402 6.7481 15.7992 7.75926 14.0072H2.85125C1.80324 14.0072 1.19129 13.3275 0.957904 12.4392C0.8365 11.2943 0.865981 10.5172 1.75759 9.66982C1.43248 9.20836 1.24126 8.58821 1.29797 8.1108C1.52051 7.39896 1.78236 7.13486 2.36422 6.85457C1.74839 5.52873 2.04259 4.64838 2.89734 3.93837C2.63079 2.88748 2.94013 1.95599 3.44886 1.3432C3.80734 0.833419 4.68953 0.540054 5.26872 0.307036C7.51238 -0.0835879 9.25955 0.346344 11.2797 0.893985C12.6203 1.25738 14.0827 1.66888 15.8476 1.91191V12.2557H15.1215C13.6341 13.9472 12.1337 15.6286 10.8478 17.0791C10.6279 18.043 10.3632 19.0143 10.0665 19.8393C9.86506 20.364 9.6806 20.8914 9.32213 21.2882L9.32158 21.2884ZM18.8252 12.2559H16.4356V1.98549C17.2447 2.04506 18.0763 2.11672 18.8068 2.12307C19.8214 2.07086 20.27 2.70493 20.2774 3.51688V10.8072C20.2719 11.7975 19.6892 12.2782 18.8252 12.2561V12.2559ZM18.7886 4.33297C19.2759 4.33297 19.671 3.93887 19.671 3.45263C19.671 2.96638 19.2761 2.57228 18.7886 2.57228C18.3013 2.57228 17.9062 2.96638 17.9062 3.45263C17.9062 3.93887 18.3013 4.33297 18.7886 4.33297Z" fill="#CF3838"></path></svg> */}
                                                                        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M16.5498 10.1587L20.7532 5.95535M20.7532 5.95535L16.5498 1.75195M20.7532 5.95535H7.09213C4.1903 5.95535 1.83789 8.30778 1.83789 11.2096C1.83789 14.1114 4.1903 16.4638 7.09213 16.4638H12.3464" stroke="#CF3838" stroke-width="1.73147" stroke-linecap="round" stroke-linejoin="round" />
                                                                        </svg>

                                                                    </Button>
                                                                    <Button variant="lk_cls" onClick={(e) => likeButton()}>
                                                                        {/* <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M20.8911 0.236328C19.5773 0.256762 18.2923 0.62349 17.1656 1.29948C16.039 1.97547 15.1107 2.93678 14.4744 4.08633C13.8381 2.93678 12.9098 1.97547 11.7832 1.29948C10.6565 0.62349 9.37146 0.256762 8.05773 0.236328C5.9635 0.327317 3.99039 1.24346 2.56948 2.7846C1.14857 4.32574 0.395356 6.36661 0.474398 8.46133C0.474398 13.7662 6.05806 19.5598 10.7411 23.488C11.7867 24.3666 13.1086 24.8484 14.4744 24.8484C15.8402 24.8484 17.1621 24.3666 18.2077 23.488C22.8907 19.5598 28.4744 13.7662 28.4744 8.46133C28.5534 6.36661 27.8002 4.32574 26.3793 2.7846C24.9584 1.24346 22.9853 0.327317 20.8911 0.236328Z" fill="#00504E" />
                                                                </svg> */}
                                                                        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.88798 0.154947C7.90203 0.158224 7.17153 0.332039 6.673 0.622553C6.16977 0.916138 5.88233 1.31721 5.75397 1.879C5.43234 3.00316 6.31451 5.64419 7.32567 7.43612H2.41765C1.36965 7.43612 0.757696 8.11584 0.524311 9.00415C0.402906 10.149 0.432388 10.9262 1.324 11.7735C0.998886 12.235 0.807665 12.8551 0.864379 13.3326C1.08692 14.0444 1.34877 14.3085 1.93063 14.5888C1.3148 15.9146 1.609 16.795 2.46375 17.505C2.19719 18.5559 2.50654 19.4874 3.01527 20.1002C3.37375 20.6099 4.25594 20.9033 4.83513 21.1363C7.07879 21.5269 8.82596 21.097 10.8461 20.5494C12.1867 20.186 13.6491 19.7745 15.4141 19.5315V9.18764H14.6879C13.2005 7.49619 11.7001 5.81474 10.4142 4.36422C10.1943 3.40033 9.92957 2.4291 9.63293 1.60405C9.43147 1.07931 9.24701 0.551956 8.88853 0.155153L8.88798 0.154947ZM18.3916 9.18744H16.002V19.4579C16.8111 19.3983 17.6427 19.3266 18.3732 19.3203C19.3878 19.3725 19.8364 18.7384 19.8438 17.9265V10.6361C19.8383 9.64582 19.2556 9.16516 18.3916 9.18723V9.18744ZM18.355 17.1104C18.8423 17.1104 19.2374 17.5045 19.2374 17.9907C19.2374 18.477 18.8425 18.8711 18.355 18.8711C17.8677 18.8711 17.4726 18.477 17.4726 17.9907C17.4726 17.5045 17.8677 17.1104 18.355 17.1104Z" fill="#139337"></path></svg>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </NavLink>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg="3">
                                    {coaches?.filter((_, ind) => ind < 3).map((i) => {
                                        return (
                                            <div className="browse_coach_suggestion_list_card">
                                                <div className="browse_coach_suggestion_list_card_header">
                                                    <div className="user_img_sm_rnd">
                                                        {i?.profile_pic && <img width={50} height={50} className="rounded-circle" src={`${process.env.REACT_APP_COACH_PROFILE_FOLDER_PATH}${i?.profile_pic}`} alt="" onError={(e) => defaultUserImage(e)} />}
                                                        {!i?.profile_pic && <img width={50} height={50} className="rounded-circle" src={Pic} alt="" onError={(e) => defaultUserImage(e)} />}
                                                    </div>
                                                    <div className="mt-3 lh-1">
                                                        <NavLink to={!user ? "" : `/browse-coach/coach-details/${i?.coach_id}`} onClick={!user ? (e) => likeButton() : ''}>
                                                            <h5 className="m-0 mb-2 fs-18">{i?.name}</h5>
                                                        </NavLink>
                                                        {i?.skills?.map((i) => {
                                                            return (<label className="fs-12 fw-400">{i?.name}</label>)
                                                        })}
                                                    </div>
                                                    {i?.coach_type && <Badge className={`bcl_badge_type ${getBadgeColor(i?.coach_type)}`}>{i?.coach_type}</Badge>}
                                                </div>
                                                <div className="browse_coach_suggestion_list_card_mid">
                                                    <div className="ydm_row justify-content-center mt-3 flex-wrap">
                                                        <div className="d-flex align-items-center">
                                                            <span className="me-2">
                                                                <img src={ex_years} alt="" />
                                                            </span>
                                                            <span className="fw-500">
                                                                {i.workexperience > 0
                                                                    ? i.workexperience + " Years"
                                                                    : "0 Years"}{" "}
                                                            </span>
                                                        </div>
                                                        <div className="mx-2 d-flex align-items-center">
                                                            <span className="me-2">
                                                                <img src={degree} alt="" />
                                                            </span>
                                                            <span className="fw-500">
                                                                {i.followers_count ? i.followers_count : 0}{" "}
                                                            </span>
                                                        </div>
                                                        <div className="mx-2 d-flex align-items-center">
                                                            <span className="me-2">
                                                                <img src={$per_time} alt="" />
                                                            </span>
                                                            <span className="text_brand_color2 fw-600">$199</span>
                                                        </div>
                                                    </div>
                                                    {/* {i?.tags?.map((i) => {
                                                            return <p className="tagged_txt px-1 d-inline-flex m-0">#{i}</p>
                                                        })} */}
                                                    <div className="d-grid mt-3">
                                                        {i?.is_following === 1 && <button onClick={() => onClickFollow(i?.coach_id)} className="fs-12 btn-success p-2 rounded" variant="outline-success" size="md">Unfollow</button>}
                                                        {i?.is_following === 0 && <button onClick={() => onClickFollow(i?.coach_id)} className="fs-12 btn-success p-2 rounded" variant="success" size="md">Follow</button>}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="want_be_coach_card">
                                        <h5 className="fs-18">If you want to achieve excellence, you can get there today</h5>
                                        <p className="fs-12">Helping others achieve their dreams is an excellent way to start.</p>
                                        <div className="d-grid">
                                            <NavLink variant="light" className="fs-14 btn btn-light" to='/become-a-coach'>Become a Coach</NavLink>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </>
            )
            }
            {/* <LoginComponent show={loginModalShow} onHide={() => setLoginModalShow(false)} /> */}

        </>
    )
}

export default BrowseCoachGridViewComponent